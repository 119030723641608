import { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Checkbox,
    IconButton,
    ThemeProvider,
    Grid,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddModeratorIcon from '@mui/icons-material/AddModerator'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditIcon from '@mui/icons-material/Edit'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import { RefreshButton } from '../components/RefreshButton'
import { HomeButton } from '../components/HomeButton'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { NodeService } from '../libs/ambient_api/NodeService'
import EditAppPanel from '../components/EditAppPanel'
import { Service } from '../types/TechnicalTypes'
import AddNodeToAppPanel from '../components/AddNodeToAppPanel'
import TopToolBar from '../components/AppToolBar'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'

const ApplicationsPage: React.FC = () => {
    const [refresh, setRefresh] = useState<boolean>(false)
    const headers = [
        'Name',
        'ID',
        'Image',
        'State',
        'Status',
        'Ports',
        'Tags',
        'Replicas',
    ]
    const [rows, setRows] = useState<any[][]>([])
    const [showEditAppPanel, setShowEditAppPanel] = useState<boolean>(false)
    const [currentService, setCurrentService] = useState<Service | null>(null)
    const [showAddNodeToAppPanel, setShowAddNodeToAppPanel] =
        useState<boolean>(false)
    const navigate = useNavigate()

    const [token, setToken] = useState<string>('')
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        console.log('Token: ' + token)
        if (token) {
            setToken(token)
        } else {
            navigate('/')
        }
    }, [navigate])

    useEffect(() => {
        const fetchApplications = async () => {
            if (!token) {
                // console.log('No token')
                return
            }
            const applicationService = new ApplicationService(token)
            const applications = await applicationService.getServices()
            // console.log('Applications: ' + applications)
            const newRows = applications.map((application) => [
                application.name,
                application.id,
                application.requested_service_spec.image,
                application.state,
                application.status,
                application.requested_service_spec.ports,
                application.requested_service_spec.tags,
                application.requested_service_spec.replicas,
            ])
            setRows(newRows)
            // console.log('Rows: ' + rows)
        }
        // console.log('Fetching applications...')
        fetchApplications()
    }, [refresh, token])

    const getServiceIdFromRowIndex = (rowIndex: number) => {
        return rows[rowIndex][1]
    }

    const getServiceFromRowIndex = async (rowIndex: number) => {
        const appId = getServiceIdFromRowIndex(rowIndex)
        const applicationService = new ApplicationService(token)
        const application = await applicationService.getService(appId)
        return application
    }

    const handleEditClick = async (rowIndex: number) => {
        const serviceData = await getServiceFromRowIndex(rowIndex)
        // Set this service data to state, then open the edit panel with this state
        setCurrentService(serviceData)
        setShowEditAppPanel(true)
    }

    const handleDelete = async (rowIndex: number) => {
        if (!token) {
            // console.log('No token')
            return
        }
        const applicationService = new ApplicationService(token)
        const application = await applicationService.deleteService(
            rows[rowIndex][1]
        )
        // console.log('Deleted application: ' + application)
        setRefresh(!refresh)
    }

    const handleAddNodeToApp = async (rowIndex: number) => {
        if (!token) {
            // console.log('No token')
            return
        }
        const applicationService = new ApplicationService(token)
        const application = await applicationService.getService(
            rows[rowIndex][1]
        )
        console.log('Application: ' + JSON.stringify(application))
        setCurrentService(application)
        // console.log('Added application: ' + application)
        setShowAddNodeToAppPanel(true)
    }

    return (
        <div className="font-sans">
            {/* <HomeButton /> */}
            <TopToolBar />
            <Grid container direction="row" justifyContent="center">
                {/* <Grid
                    item
                    xs={12}
                >
                    <h1 className="text-center">Applications</h1>
                </Grid> */}
                {/* <HomeButton /> */}
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="right"
                        alignItems="right"
                    >
                        <IconButton
                            onClick={() => {
                                setRefresh(!refresh)
                            }}
                        >
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    {headers.map((header, index) => (
                                        <TableCell key={index}>
                                            {header}
                                        </TableCell>
                                    ))}
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell padding="checkbox">
                                            <Checkbox />
                                        </TableCell>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    handleEditClick(rowIndex)
                                                }}
                                            >
                                                <EditIcon color="secondary" />
                                            </IconButton>
                                            {showEditAppPanel && (
                                                <EditAppPanel
                                                    onClose={() =>
                                                        setShowEditAppPanel(
                                                            false
                                                        )
                                                    }
                                                    token={token}
                                                    application={
                                                        currentService!
                                                    }
                                                />
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    handleAddNodeToApp(rowIndex)
                                                }}
                                            >
                                                <AddBoxIcon color="secondary" />
                                            </IconButton>
                                            {showAddNodeToAppPanel && (
                                                <AddNodeToAppPanel
                                                    onClose={() =>
                                                        setShowAddNodeToAppPanel(
                                                            false
                                                        )
                                                    }
                                                    token={token}
                                                    application={
                                                        currentService!
                                                    }
                                                />
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    console.log(
                                                        'Navigate to: ' +
                                                            `/apps/${getServiceIdFromRowIndex(
                                                                rowIndex
                                                            )}`
                                                    )
                                                    navigate(
                                                        `/apps/${getServiceIdFromRowIndex(
                                                            rowIndex
                                                        )}`
                                                    )
                                                }}
                                            >
                                                <ArrowOutwardIcon color="secondary" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleDelete(rowIndex)
                                                }
                                            >
                                                <DeleteIcon color="primary" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default ApplicationsPage
