import { createTheme } from '@mui/material'

export const lightTheme = createTheme({
    palette: {
        // type: 'light',
        primary: {
            main: '#d33682',
        },
        secondary: {
            main: '#2aa198',
        },
        background: {
            default: '#f0efed',
            paper: '#ebeae8',
        },
        text: {
            primary: '#000',
            secondary: '#5c5c5c',
        },
    },
})

export const darkTheme = createTheme({
    palette: {
        // type: 'dark',
        primary: {
            main: '#6c71c4',
        },
        secondary: {
            main: '#268bd2',
        },
        background: {
            default: '#002b36',
            paper: '#073642',
        },
        text: {
            primary: '#fff',
            secondary: '#93a1a1',
        },
    },
})

export interface ThemeCustomPallete {
    clusterDragAndDrop: {
        cluster: {
            active: string
            inactive: string
        }
        node: {
            active: string
            inactive: string
        }
    }
}

export const customDarkPallete: ThemeCustomPallete = {
    clusterDragAndDrop: {
        cluster: {
            active: '#2a75a1',
            inactive: '#073642',
        },
        node: {
            active: '#268bd2',
            inactive: '#002b36',
        },
    },
}

export const customLightPallete: ThemeCustomPallete = {
    clusterDragAndDrop: {
        cluster: {
            active: '#2a75a1',
            inactive: '#073642',
        },
        node: {
            active: '#268bd2',
            inactive: '#002b36',
        },
    },
}
