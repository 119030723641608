export class HttpError extends Error {
    statusCode: number

    constructor(message: string, statusCode: number) {
        super(message)
        this.statusCode = statusCode

        // Set the prototype explicitly (important when using TypeScript)
        Object.setPrototypeOf(this, HttpError.prototype)
    }
}
