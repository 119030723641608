import { useEffect, useState } from 'react'
import { Button, Grid, Typography, Paper, Box } from '@mui/material'
import { Collapse } from '@mui/material'
import { Divider, Card } from '@mui/material'
import { useNavigate } from 'react-router'
import { NodeService } from '../libs/ambient_api/NodeService'
import { Node } from '../types/TechnicalTypes'
import { LoadingScreen } from '../components/HomeScreenComp'
import { HomeButton } from '../components/HomeButton'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { AppBar } from '@mui/material'
import TopToolBar from '../components/AppToolBar'

const NodeInfoPage: React.FC = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState<string>('')
    const [node, setNode] = useState<Node | null>(null)
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
    const [interfacesOpen, setInterfacesOpen] = useState(false) // Start closed

    const nodeService = new NodeService()

    // auth useEffect
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (token) {
            setToken(token)
        } else {
            navigate('/home')
        }
    }, [navigate])

    // make API call to get node info
    useEffect(() => {
        if (!token) {
            return
        }
        // get node ID from URL
        const url = window.location.pathname
        console.log('URL:', url)
        const nodeId = url.split('/')[2]
        console.log('Node ID:', nodeId)
        const fetchNode = async () => {
            const fetchedNode = await nodeService.getNode(token, nodeId)
            console.log('Fetched node:', fetchedNode)
            setNode(fetchedNode)
        }
        fetchNode()
        setShowLoadingScreen(false)
    }, [token])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            style={{ font: 'sans' }}
        >
            {showLoadingScreen && <LoadingScreen />}
            <TopToolBar />
            <Grid item xs={8}>
                <Grid container direction="column" justifyContent="center">
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        {/* 
                    <Paper
                        elevation={3}
                        style={{ padding: '20px', marginTop: '20px' }}
                    > */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Card variant="outlined" style={{ width: '25%' }}>
                                <Grid item padding={'20px'}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        justifyContent={'center'}
                                        alignContent={'center'}
                                    >
                                        <Typography variant="h6">
                                            Status{' '}
                                            <Typography
                                                variant="h5"
                                                color={
                                                    node?.status === 'active'
                                                        ? 'green'
                                                        : node?.status ===
                                                            'error'
                                                          ? 'red'
                                                          : 'gray'
                                                }
                                            >
                                                {node?.status === 'active'
                                                    ? 'Active  '
                                                    : node?.status === 'error'
                                                      ? 'Error'
                                                      : 'Pending'}
                                                {/* <CloudDoneIcon /> */}
                                                {node?.status === 'active' ? (
                                                    <CloudDoneIcon />
                                                ) : (
                                                    <CloudOffIcon />
                                                )}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="caption">
                                            Last Seen:{' '}
                                            {node
                                                ? new Date(
                                                      node!.last_seen!
                                                  ).toLocaleString()
                                                : 'Unknown'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                            <Divider />
                            <Card variant="outlined" style={{ width: '25%' }}>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    padding={'20px'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h6">
                                        Architecture
                                    </Typography>
                                    <Typography
                                        variant="button"
                                        padding={'30px'}
                                    >
                                        {node?.architecture}
                                    </Typography>
                                </Grid>
                            </Card>
                            {/* <Divider /> */}
                            {/* <Box>
                                <Typography variant="h6">
                                    Cluster: {node?.cluster}
                                </Typography>
                            </Box> */}
                            <Divider />
                            <Card variant="outlined" style={{ width: '25%' }}>
                                <Grid
                                    container
                                    direction={'column'}
                                    justifyContent={'center'}
                                    padding={'20px'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h6">Role</Typography>
                                    {/* <Box m={1} /> */}
                                    <Typography
                                        variant="button"
                                        padding={'30px'}
                                    >
                                        {node?.role}
                                    </Typography>
                                </Grid>
                            </Card>
                            <Divider />
                            {/* <Box padding={'20px'}>
                                <Typography variant="h6">
                                    Interfaces: {node?.interfaces.length}
                                </Typography>
                            </Box> */}
                        </Grid>
                    </Paper>

                    <Button onClick={() => setInterfacesOpen(!interfacesOpen)}>
                        <Typography variant="body1" style={{ padding: '20px' }}>
                            {interfacesOpen
                                ? 'Hide Interfaces'
                                : 'Show Interfaces'}
                        </Typography>
                    </Button>

                    <Collapse in={interfacesOpen}>
                        <Paper
                            elevation={3}
                            style={{ padding: '20px', marginTop: '20px' }}
                        >
                            <Grid
                                container
                                direction={'column'}
                                rowSpacing={'20px'}
                                alignItems={'center'}
                            >
                                <Box m={3} />
                                <Typography variant="h6">Interfaces</Typography>
                                <Box m={3} />
                                <Grid
                                    container
                                    direction={'row'}
                                    rowSpacing={'20px'}
                                >
                                    {node?.interfaces.map((interface_) => (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                padding: '20px',
                                                margin: '20px',
                                                width: '45%',
                                            }}
                                        >
                                            <Typography variant="h6">
                                                {interface_.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                Type: {interface_.type}
                                            </Typography>
                                            <Typography variant="body1">
                                                IPv4: {interface_.ipv4_address}
                                            </Typography>
                                            <Typography variant="body1">
                                                IPv6: {interface_.ipv6_address}
                                            </Typography>
                                        </Paper>
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Collapse>
                    {/* </Paper> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NodeInfoPage
