import { Cluster } from '../types/TechnicalTypes'
import {
    Portal,
    Grid,
    Typography,
    Box,
    Card,
    TextField,
    Paper,
    Button,
} from '@mui/material'
import { Form } from 'react-router-dom'
import { ClusterService } from '../libs/ambient_api/ClusterService'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import { useTheme } from '../ThemeContext'
import { darkTheme, lightTheme } from './Themes'

export interface EditClusterPanelProps {
    open: boolean
    onClose: (confirmed: boolean) => void
    token: string
    cluster: Cluster
}

export const EditClusterPanel: React.FC<EditClusterPanelProps> = ({
    open,
    onClose,
    token,
    cluster,
}) => {
    const [editableCluster, setEditableCluster] = useState<Cluster>(cluster)
    const themeContext = useTheme()
    const theme = themeContext.themeMode === 'light' ? lightTheme : darkTheme

    const handleSaveChanges = async () => {
        try {
            const clusterSvc = new ClusterService(token)
            await clusterSvc.putCluster(editableCluster.id, editableCluster)
            onClose(true)
        } catch (e) {
            console.error('Failed to save changes', e)
        }
    }

    return ReactDOM.createPortal(
        open ? (
            <div
                // Overlay to block interactions with the rest of the app
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }}
            >
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000, // Ensure it's above other content
                    }}
                >
                    <Paper
                        style={{
                            padding: '40px',
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            direction={'column'}
                        >
                            <Grid item>
                                <Typography variant="h6" align="center">
                                    Edit Cluster
                                </Typography>
                            </Grid>
                            <Grid item>
                                <form>
                                    <TextField
                                        label="Cluster Name"
                                        name="name"
                                        value={editableCluster.name}
                                        onChange={(e) => {
                                            setEditableCluster(
                                                (prevCluster) => ({
                                                    ...prevCluster,
                                                    name: e.target.value,
                                                })
                                            )
                                        }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Cluster Description"
                                        name="description"
                                        value={editableCluster.description}
                                        onChange={(e) => {
                                            setEditableCluster(
                                                (prevCluster) => ({
                                                    ...prevCluster,
                                                    description: e.target.value,
                                                })
                                            )
                                        }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Cluster Tags"
                                        name="tags"
                                        value={editableCluster.tags}
                                        onChange={(e) => {
                                            setEditableCluster(
                                                (prevCluster) => ({
                                                    ...prevCluster,
                                                    tags: e.target.value.split(
                                                        ','
                                                    ),
                                                })
                                            )
                                        }}
                                        fullWidth
                                        margin="normal"
                                    />
                                </form>
                                <Grid item>
                                    <Box mt={3} />
                                    <Grid container justifyContent="center">
                                        <Button
                                            startIcon={<CheckIcon />}
                                            style={{ marginRight: '10px' }}
                                            onClick={handleSaveChanges}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            startIcon={<CancelIcon />}
                                            style={{ marginRight: '10px' }}
                                            onClick={() => onClose(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        ) : null,
        document.body // Append to body so it overlays the entire app
    )
}
