import { useEffect, useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NodeService, NodeOrder } from '../libs/ambient_api/NodeService'
import UserService from '../libs/ambient_api/UserService'
import { ClusterService } from '../libs/ambient_api/ClusterService'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import AddNodePanel from '../components/AddNodePanel'
import AddClusterPanel from '../components/AddClusterPanel'
import AddApplicationPanel from '../components/AddApplicationPanel'
import EditNodePanel from '../components/EditNodePanel'
import { HomeButton } from '../components/HomeButton'
import NodeTerminalPanel from '../components/NodeTerminalPanel'
import {
    AppBar,
    Button,
    Card,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    ThemeProvider,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import HelpIcon from '@mui/icons-material/Help'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTheme, makeStyles } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingScreen } from '../components/HomeScreenComp'
import { User } from '../types/User'
import { Cluster } from 'cluster'
import Divider from '@mui/material/Divider'
import { useTheme as useThemeContext } from '../ThemeContext'
import DeleteIcon from '@mui/icons-material/Delete'
import AddModeratorIcon from '@mui/icons-material/AddModerator'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditIcon from '@mui/icons-material/Edit'
import { Node } from '../types/TechnicalTypes'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import GenerateApiTokenPanel from '../components/APIKeyPanel'
import SidebarMenu from '../components/SidebarMenu'

import TopToolBar from '../components/AppToolBar'

const HomePage: React.FC = () => {
    // console.log('In HomePage.tsx')
    const theme = useTheme()
    const navigate = useNavigate()
    const {
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        user,
        isLoading,
        handleRedirectCallback,
    } = useAuth0()
    const [nodeCount, setNodeCount] = useState<number>(0)
    const [clusterCount, setClusterCount] = useState<number>(0)
    const [serviceCount, setServiceCount] = useState<number>(0)
    const [showAddNodePanel, setShowAddNodePanel] = useState(false)
    const [showAddClusterPanel, setShowAddClusterPanel] = useState(false)
    const [showAddApplicationPanel, setShowAddApplicationPanel] =
        useState(false)
    const [nodes, setNodes] = useState<Node[]>([])
    const [showTerminal, setShowTerminal] = useState<boolean>(false)
    const [token, setToken] = useState<string>('')
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
    const [ambientUser, setAmbientUser] = useState<User | undefined>(undefined)
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false)
    const [showEditNodePanel, setShowEditNodePanel] = useState<boolean>(false)
    const { themeMode, toggleTheme } = useThemeContext()
    // const [authCode, setAuthCode] = useState<string>('')
    const [apiToken, setApiToken] = useState<string>('')

    const nodeService = new NodeService()

    const setThemeModeFromUserPreferences = (user: User) => {
        console.log('Setting theme mode from user preferences')
        if (!user.preferences) {
            console.log('No user preferences')
            return
        }
        const darkMode = user.preferences.dark_mode
        console.log('Dark mode: ' + darkMode)
        if (darkMode && themeMode === 'light') {
            console.log('Setting theme to dark')
            toggleTheme()
        }
        // toggleTheme()
    }

    // auth useEffect
    useEffect(() => {
        if (isLoading) {
            return
        }
        const fetchToken = async () => {
            // const _href = window.location.href
            // const params = _href.split('?')[1]
            // if (!params) {
            //     // console.log('No params')
            //     return
            // }
            // const code_params = params.split('&')[0]
            // const _authCode = code_params.split('=')[1]
            // if (!_authCode) {
            //     console.log('No auth code')
            //     return
            // }
            // setAuthCode(_authCode)
            // console.log('Authorization Code:', _authCode);

            const token_ = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                },
            })
            if (!token_) {
                // console.log('No token')
                return
            }
            // console.log('Token: ' + token_)
            setToken(token_)
            localStorage.setItem('access_token', token_)
            const token = localStorage.getItem('access_token')
            if (!token) {
                // console.log('No token in local storage')
                return
            }
            // console.log('Token: ' + token)
            setToken(token)
            // console.log('Token set')

            // console.log('Getting user from token...')
            const userService = new UserService(token)
            let userData
            try {
                userData = await userService.getUserFromToken()
                console.log('User data: ' + JSON.stringify(userData))
            } catch (err) {
                console.log('Error getting user from token: ' + err)
            }

            if (!userData) {
                // console.log('No user data, creating user...')
                // setShowLoadingScreen(true)
                const acctType = localStorage.getItem('acct_type') || ''
                if (!acctType) {
                    // console.log('No acct type in local storage')
                    navigate('/signup')
                }
                let orgCreateData
                if (acctType === 'organization') {
                    orgCreateData = localStorage.getItem('org_create_data')
                    orgCreateData = JSON.parse(orgCreateData || '')
                    if (!orgCreateData) {
                        // console.log('No org create data in local storage')
                        navigate('/signup')
                    }
                }

                userData = await userService.createUserFromToken(
                    acctType,
                    orgCreateData
                )
                // setThemeModeFromUserPreferences(userData!)
                // console.log('User data: ' + JSON.stringify(userData))
                setShowLoadingScreen(false)
            }

            // we shouldn't get here wihtout a user
            if (!userData) {
                // console.log('Somehow we still have no user data')
                return
            }

            // console.log('Saving user to local storage...')
            // console.log('User saved to local storage')
            setAmbientUser(userData)
            console.log('User: ' + JSON.stringify(userData))
            setThemeModeFromUserPreferences(userData!)
            setShowLoadingScreen(false)
        }
        if (isAuthenticated) {
            // console.log('User is authenticated')
            fetchToken()
        } else {
            // console.log('User is not authenticated')
            navigate('/login')
        }
    }, [isLoading, isAuthenticated, user])

    // if Admin, set isAdmin to true
    useEffect(() => {
        const fetchData = async () => {
            if (!user) {
                return
            }

            const userInfo = user
            console.log('User info: ' + JSON.stringify(userInfo))

            if (
                userInfo.email &&
                userInfo.email.endsWith('@ambientlabscomputing.com')
            ) {
                // console.log('User is admin')
                localStorage.setItem('isAdmin', 'true')
            } else {
                // console.log('User is not admin')
                localStorage.setItem('isAdmin', 'false')
                // TODO: [SD-78] get user org from API
            }
        }
        fetchData()
    }, [user])

    // query data useEffect
    useEffect(() => {
        if (!ambientUser) {
            return
        }
        const fetchData = async () => {
            const nodes = await nodeService.getNodes(
                token,
                'timestamp',
                NodeOrder.desc,
                null
            )
            if (!nodes) {
                setNodeCount(0)
            } else {
                setNodes(nodes)
                setNodeCount(nodes.length)
            }

            // const clusterService = new ClusterService(token)
            // const clusters = await clusterService.getClusters()
            // if (!clusters) {
            //     setServiceCount(0)
            // } else {
            //     setClusterCount(clusters.length)
            // }
            setClusterCount(0)

            const appService = new ApplicationService(token)
            const services = await appService.getServices()
            if (!services) {
                setServiceCount(0)
            } else {
                setServiceCount(services.length)
            }
        }
        fetchData()
    }, [ambientUser])

    const seeMoreNodesButton = (
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/nodes')}
        >
            <Typography variant="button" style={{ lineHeight: '18px' }}>
                See More
            </Typography>
        </Button>
    )

    const nodesHeaders = ['Name', 'Status', 'ID', 'Role', 'Arch']
    const nodesRows = nodes.map((node) => {
        return [node.name, node.status, node.id, node.role, node.architecture]
    })

    const getNodeFromRowIndex = (rowIndex: number) => {
        return nodes[rowIndex]
    }

    const nodesTable = (
        <Paper elevation={1}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%', width: '100%' }}
            >
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox />
                                </TableCell>
                                {nodesHeaders.map((header, index) => (
                                    <TableCell key={index}>{header}</TableCell>
                                ))}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nodesRows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    {row.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex}>
                                            {cell === 'active' ? (
                                                <CloudDoneIcon />
                                            ) : cell === 'pending' ||
                                              cell === 'error' ? (
                                                <CloudOffIcon />
                                            ) : (
                                                cell
                                            )}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                const node =
                                                    getNodeFromRowIndex(
                                                        rowIndex
                                                    )
                                                navigate(`/nodes/${node.id}`)
                                            }}
                                        >
                                            <ArrowOutwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() =>
                                                setShowEditNodePanel(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {showEditNodePanel && (
                                            <EditNodePanel
                                                onClose={() =>
                                                    setShowEditNodePanel(false)
                                                }
                                                token={token}
                                                node={getNodeFromRowIndex(
                                                    rowIndex
                                                )}
                                            />
                                        )}
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                const node =
                                                    getNodeFromRowIndex(
                                                        rowIndex
                                                    )
                                                nodeService.deleteNode(
                                                    token,
                                                    node.id
                                                )
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    )
    const NodesPanel = (
        <Paper elevation={1} style={{ width: '100%' }}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={9} className="p-5">
                    <Typography variant="h5">Nodes</Typography>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: '10px' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                    >
                        <Grid item xs={1}>
                            <IconButton
                                color="inherit"
                                style={{
                                    width: 41,
                                    height: 40,
                                    borderRadius: '4xs',
                                    fontSize: 'large',
                                }}
                                // onClick={() => setRefreshNodes(!refreshNodes)}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} style={{ paddingRight: '20px' }}>
                            {seeMoreNodesButton}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: '10px' }}>
                    {nodesTable}
                </Grid>
            </Grid>
        </Paper>
    )

    // const topToolBar = (
    //     // <AppBar position="static" color="secondary">
    //     <AppBar
    //         position="static"
    //         style={{
    //             backgroundColor: theme.palette.background.paper,
    //             color: theme.palette.primary.contrastText,
    //         }}
    //     >
    //         <Toolbar>
    //             <Grid
    //                 container
    //                 direction="row"
    //                 justifyContent="space-between"
    //                 alignItems="center"
    //             >
    //                 <Box style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     width: '5%',
    //                     padding: '10px'
    //                 }}/>
    //                 <Typography variant="h6" style={{
    //                     flexGrow: 1
    //                 }}>
    //                     Ambient Cloud
    //                 </Typography>
    //                 <IconButton
    //                     color="inherit"
    //                     onClick={() => setShowUserMenu(!showUserMenu)}
    //                 >
    //                     <img alt="" src="/user--user-01.svg" />
    //                 </IconButton>
    //             </Grid>
    //         </Toolbar>
    //     </AppBar>
    // )

    const infoPanel = (
        <Paper elevation={1}>
            <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        align="center"
                        style={{ padding: '25px' }}
                    >
                        Edge Resources
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: '25px ' }}>
                    <Box className="flex flex-col items-center justify-center h-[100px]">
                        <Button onClick={() => navigate('/nodes')}>
                            <Paper style={{ padding: '25px' }}>
                                <Typography variant="h4" style={{}}>
                                    {nodeCount}
                                </Typography>
                                <Typography variant="body1" style={{}}>
                                    Nodes
                                </Typography>
                            </Paper>
                        </Button>
                    </Box>
                </Grid>
                {/* <Grid item xs={3} style={{ paddingBottom: '25px ' }}>
                    <Box className="flex flex-col items-center justify-center h-[100px]">
                        <Button onClick={() => navigate('/clusters')}>
                            <Paper style={{ padding: '25px' }}>
                                <Typography variant="h4" style={{}}>
                                    {clusterCount}
                                </Typography>
                                <Typography variant="body1" style={{}}>
                                    Clusters
                                </Typography>
                            </Paper>
                        </Button>
                    </Box>
                </Grid> */}
                <Grid item xs={3} style={{ paddingBottom: '25px' }}>
                    <Box className="flex flex-col items-center justify-center h-[100px]">
                        <Button onClick={() => navigate('/apps')}>
                            <Paper style={{ padding: '25px' }}>
                                <Typography variant="h4" style={{}}>
                                    {serviceCount}
                                </Typography>
                                <Typography variant="body1" style={{}}>
                                    Applications
                                </Typography>
                            </Paper>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )

    const deployPanel = (
        <Paper elevation={1}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        align="center"
                        style={{ padding: '10px', paddingLeft: '20px' }}
                    >
                        Deploy
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: '5px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowAddApplicationPanel(true)}
                    >
                        Launch App
                    </Button>
                    {showAddApplicationPanel && (
                        <AddApplicationPanel
                            onClose={() => setShowAddApplicationPanel(false)}
                            token={token}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: '5px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingBottom: '25px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddNodePanel(true)}
                    >
                        Add Node
                    </Button>
                    {showAddNodePanel && (
                        <AddNodePanel
                            onClose={() => setShowAddNodePanel(false)}
                            token={token}
                        />
                    )}
                </Grid>
                {/* <Grid
                    item
                    xs={12}
                    style={{
                        padding: '5px',
                        paddingLeft: '20px',
                        paddingBottom: '25px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddClusterPanel(true)}
                    >
                        Add Cluster
                    </Button>
                    {showAddClusterPanel && (
                        <AddClusterPanel
                            onClose={() => setShowAddClusterPanel(false)}
                            owner_id={ownerId}
                            token={token}
                        />
                    )}
                </Grid> */}
            </Grid>
        </Paper>
    )

    // const user_menu = (
    //     <Paper elevation={5}>
    //         <Grid
    //             container
    //             direction="column"
    //             justifyContent="center"
    //             alignItems="flex-start"
    //         >
    //             <Grid item xs={12}>
    //                 <Typography
    //                     variant="h6"
    //                     align="center"
    //                     style={{ padding: '10px', paddingLeft: '20px' }}
    //                 >
    //                     {user?.name}
    //                 </Typography>
    //                 <Typography
    //                     variant="body2"
    //                     align="center"
    //                     style={{
    //                         padding: '1px',
    //                         paddingLeft: '10px',
    //                         paddingRight: '10px',
    //                     }}
    //                 >
    //                     {user?.email}
    //                 </Typography>
    //                 <Divider />
    //             </Grid>
    //             <Grid
    //                 item
    //                 xs={12}
    //                 style={{ padding: '5px', paddingLeft: '20px' }}
    //             >
    //                 <Button onClick={() => navigate('/preferences')}>
    //                     <Typography
    //                         variant="body1"
    //                         align="center"
    //                         style={{ padding: '10px', paddingLeft: '20px' }}
    //                         color={'textPrimary'}
    //                         // onClick={() => navigate('/preferences')}
    //                     >
    //                         Preferences
    //                     </Typography>
    //                 </Button>
    //                 <Divider />
    //             </Grid>
    //             <Grid
    //                 item
    //                 xs={12}
    //                 style={{ padding: '5px', paddingLeft: '20px' }}
    //             >
    //                 <Button onClick={() => navigate('/settings')}>
    //                     <Typography
    //                         variant="body1"
    //                         align="center"
    //                         style={{ padding: '10px', paddingLeft: '20px' }}
    //                         color={'textPrimary'}
    //                     >
    //                         Settings
    //                     </Typography>
    //                 </Button>
    //                 <Divider />
    //             </Grid>
    //             <Grid
    //                 item
    //                 xs={12}
    //                 style={{
    //                     padding: '5px',
    //                     paddingLeft: '20px',
    //                     alignContent: 'right',
    //                 }}
    //             >
    //                 <Button onClick={() => loginWithRedirect()}>
    //                     <Typography>Log Out</Typography>
    //                 </Button>
    //             </Grid>
    //         </Grid>
    //     </Paper>
    // )

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            style={{ font: 'sans' }}
        >
            {showLoadingScreen && <LoadingScreen />}
            <TopToolBar
            // user={user}
            />
            <SidebarMenu />
            <Grid container direction="row" justifyContent="center" spacing={3}>
                <div
                    style={{
                        position: 'fixed',
                        top: 60,
                        right: 10,
                        zIndex: 10,
                    }}
                >
                    {/* {showUserMenu && user_menu} */}
                </div>
                <Grid item xs={8} style={{ paddingTop: '50px' }}>
                    {infoPanel}
                </Grid>
                <Grid item xs={6}>
                    {NodesPanel}
                </Grid>
                <Grid item xs={2}>
                    {deployPanel}
                </Grid>
                <Grid item xs={8}>
                    <GenerateApiTokenPanel
                        token={token}
                        setApiToken={setApiToken}
                        apiToken={apiToken}
                    />
                </Grid>
            </Grid>
            {showTerminal && (
                <NodeTerminalPanel handleClose={() => setShowTerminal(false)} />
            )}
        </Grid>
    )
}

export default HomePage
