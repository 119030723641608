import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopToolBar from '../components/AppToolBar'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Modal,
    Paper,
    TablePagination,
    Checkbox,
    IconButton,
    ThemeProvider,
    Grid,
    Typography,
    Divider,
    Box,
    Tooltip,
    TextField,
    Button,
    Drawer,
    Select,
    InputLabel,
    MenuItem,
    Chip,
    Alert,
    Snackbar,
    Link,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'
import {
    RegistryService,
    GetRegistriesResponse,
} from '../libs/ambient_api/RegistriesService'
import {
    Registry,
    RegistryAuth,
    NodeWithRegistries,
    Request,
} from '../types/TechnicalTypes'
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import { PaddingTwoTone } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import LockResetIcon from '@mui/icons-material/LockReset'

const RegistriesPage: React.FC = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState<string>('')
    const [refresh, setRefresh] = useState<boolean>(false)
    const [registriesResponse, setRegistriesResponse] =
        useState<GetRegistriesResponse | null>(null)
    const [showEditRegistryModal, setShowEditRegistryModal] =
        useState<boolean>(false)
    const [selectedRegistry, setSelectedRegistry] = useState<Registry | null>(
        null
    )
    const [registryAuths, setRegistryAuths] = useState<RegistryAuth[] | null>(
        null
    )

    const [showRegistryCredsTable, setShowRegistryCredsTable] =
        useState<boolean>(false)

    const [editName, setEditName] = useState<string>('')
    const [editUrl, setEditUrl] = useState<string>('')
    const [editDescription, setEditDescription] = useState<string>('')
    const [editType, setEditType] = useState<string>('')

    const [showAddRegistryModal, setShowAddRegistryModal] =
        useState<boolean>(false)
    const [addName, setAddName] = useState<string>('')
    const [addUrl, setAddUrl] = useState<string>('')
    const [addDescription, setAddDescription] = useState<string>('')
    const [addType, setAddType] = useState<string>('Choose a type')

    const [showUserName, setShowUserName] = useState<boolean>(false)

    const [showAddAuthCredsModal, setShowAddAuthCredsModal] =
        useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [alertContent, setAlertContent] = useState<string>('')

    const [showSnackBar, setShowSnackBar] = useState<boolean>(false)
    const [snackBarContent, setSnackBarContent] = useState<any>(null)

    const [selectedNodes, setSelectedNodes] = useState<
        NodeWithRegistries[] | null
    >(null)

    const [requestsResponse, setRequestsResponse] = useState<Request[] | null>(
        null
    )

    // auth check
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        console.log('Token: ' + token)
        if (token) {
            setToken(token)
        } else {
            navigate('/')
        }
    }, [navigate])

    // get registries
    useEffect(() => {
        const fetchData = async () => {
            const registrySvc = new RegistryService(token)
            const registriesResponse = await registrySvc.getRegistries()
            setRegistriesResponse(registriesResponse)
        }
        if (token) {
            // get registries
            fetchData()
        }
    }, [token, refresh])

    // refresh registries and creds
    useEffect(() => {
        const fetchData = async () => {
            if (selectedRegistry) {
                const registry_service = new RegistryService(token)
                registry_service
                    .getRegistryAuths(selectedRegistry.id)
                    .then((response) => {
                        console.log('Auths: ' + response.results)
                        setRegistryAuths(response.results)
                    })
                    .catch((error) => {
                        console.log('Error getting registry auths: ' + error)
                    })
            } else {
                return
            }
        }
        if (token) {
            // get registries
            fetchData()
        }
    }, [refresh])

    // refresh nodes
    useEffect(() => {
        const fetchData = async () => {
            if (selectedRegistry) {
                const registry_service = new RegistryService(token)
                registry_service
                    .getNodesForRegistry(selectedRegistry.id)
                    .then((response) => {
                        console.log('Nodes: ' + response)
                        setSelectedNodes(response)
                    })
                    .catch((error) => {
                        console.log('Error getting nodes: ' + error)
                    })
            } else {
                return
            }
        }
        if (token) {
            // get registries
            fetchData()
        }
    }, [selectedRegistry, refresh])

    // refresh requests
    useEffect(() => {
        const fetchData = async () => {
            if (selectedRegistry) {
                const registry_service = new RegistryService(token)
                registry_service
                    .getRequestsForRegistry(selectedRegistry.id)
                    .then((requests) => {
                        console.log('Requests: ' + requests)
                        if (requests) {
                            setRequestsResponse(requests)
                        }
                    })
                    .catch((error) => {
                        console.log('Error getting requests: ' + error)
                    })
            } else {
                return
            }
        }
        if (token) {
            // get registries
            fetchData()
        }
    }, [selectedRegistry, refresh])

    // show alert, wait 3 seconds, then hide
    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 3000)
        }
    }, [showAlert])

    const alert = (
        <Alert
            severity="success"
            style={{
                position: 'absolute',
                top: '10px',
                left: '50%',
                transform: 'translate(-50%, 0)',
            }}
        >
            {alertContent}
        </Alert>
    )

    const snackBar = (
        <Snackbar
            open={showSnackBar}
            autoHideDuration={6000}
            onClose={() => setShowSnackBar(false)}
            // message={snackBarContent}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            {snackBarContent}
        </Snackbar>
    )

    const registriesTable = (
        <Box padding={4}>
            <div
            // style={{ outline: '1px solid lightgrey' }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox />
                            </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {registriesResponse &&
                            registriesResponse.results.map(
                                (registry: Registry) => (
                                    <TableRow key={registry.id}>
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>{registry.id}</TableCell>
                                        <TableCell>{registry.name}</TableCell>
                                        <TableCell>{registry.url}</TableCell>
                                        <TableCell>
                                            {registry.description}
                                        </TableCell>
                                        <TableCell>
                                            {registry.registry_type}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit" arrow>
                                                <IconButton
                                                    onClick={() =>
                                                        handleEditClick(
                                                            registry
                                                        )
                                                    }
                                                >
                                                    <EditIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title="View Credentials"
                                                arrow
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        handleViewCredentialsClick(
                                                            registry
                                                        )
                                                    }
                                                >
                                                    <LockIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title="Add Credentials"
                                                arrow
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        handleAddCredsClick(
                                                            registry
                                                        )
                                                    }
                                                >
                                                    <EnhancedEncryptionIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" arrow>
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteClick(
                                                            registry
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                    </TableBody>
                </Table>
            </div>
        </Box>
    )

    const handleEditClick = (registry: Registry) => {
        console.log('Edit registry: ' + registry.name)
        setEditName(registry.name)
        setEditUrl(registry.url)
        setEditDescription(registry.description ? registry.description : '')
        setEditType(registry.registry_type)
        setSelectedRegistry(registry)
        setShowEditRegistryModal(true)
    }

    const handleViewCredentialsClick = (registry: Registry) => {
        console.log('View credentials for registry: ' + registry.name)
        const registry_service = new RegistryService(token)
        registry_service
            .getRegistryAuths(registry.id)
            .then((response) => {
                console.log('Auths: ' + response.results)
                setRegistryAuths(response.results)
            })
            .catch((error) => {
                console.log('Error getting registry auths: ' + error)
            })
        setSelectedRegistry(registry)
        setShowRegistryCredsTable(!showRegistryCredsTable)
    }

    const handleDeleteClick = (registry: Registry) => {
        console.log('Delete registry: ' + registry.name)
        const registry_service = new RegistryService(token)
        registry_service
            .deleteRegistry(registry.id)
            .then(() => {
                console.log('Registry deleted')
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log('Error deleting registry: ' + error)
            })
        setRefresh(!refresh)
    }

    const handleAddCredsClick = (registry: Registry) => {
        console.log('Add creds for registry: ' + registry.name)
        setSelectedRegistry(registry)
        setShowAddAuthCredsModal(true)
    }

    const registryCredsTable = (
        <Paper>
            <Box padding={4}>
                <Typography variant="h6">
                    Registry Credentials{' '}
                    {selectedRegistry ? `-  ${selectedRegistry.name}` : ''}
                </Typography>
                {registryAuths && registryAuths.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Registry</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registryAuths &&
                                registryAuths.map((auth: RegistryAuth) => (
                                    <TableRow key={auth.id}>
                                        <TableCell>{auth.id}</TableCell>
                                        <TableCell>
                                            {auth.registry_id}
                                        </TableCell>
                                        <TableCell>
                                            {/* {auth.username} */}
                                            {showUserName ? (
                                                auth.username
                                            ) : (
                                                <Tooltip
                                                    title="Show Username"
                                                    arrow
                                                >
                                                    <Chip
                                                        label="**********"
                                                        onClick={() =>
                                                            setShowUserName(
                                                                true
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title="Authenticate Nodes"
                                                arrow
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        handleAuthenticateNodes(
                                                            auth
                                                        )
                                                    }}
                                                >
                                                    <LockResetIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" arrow>
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteRegistryCreds(
                                                            auth
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>
                        Choose Registry to View Saved Credentials
                    </Typography>
                )}
            </Box>
        </Paper>
    )

    const handleAuthenticateNodes = (auth: RegistryAuth) => {
        console.log('Authenticate nodes: ' + auth.id)
        const fetchData = async () => {
            const registry_service = new RegistryService(token)
            const auth_request_location =
                await registry_service.triggerRegistryAuthentication(auth.id)
            console.log('Auth request location: ' + auth_request_location)
            const location_path = auth_request_location.split('/').pop()
            console.log('Location path: ' + location_path)
            setAlertContent('Authentication request sent')
            setShowAlert(true)
            const snackBarContent = (
                <Alert severity="info" variant="filled">
                    <Link
                        href={`/requests/${location_path}`}
                        // color="secondary"
                    >
                        Click here
                    </Link>{' '}
                    to view authentication request status.
                </Alert>
            )
            setSnackBarContent(snackBarContent)
            setShowSnackBar(true)
        }
        fetchData()
    }

    const handleDeleteRegistryCreds = (auth: RegistryAuth) => {
        console.log('Delete registry creds: ' + auth.id)
        const registry_service = new RegistryService(token)
        registry_service
            .deleteRegistryAuth(auth.id)
            .then(() => {
                console.log('Registry creds deleted')
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log('Error deleting registry creds: ' + error)
            })
        setRefresh(!refresh)
    }

    const editRegistryModal = (
        <Modal
            open={true}
            onClose={() => setShowEditRegistryModal(false)}
            style={{
                width: '50%',
                // position: 'absolute',
                top: '20%',
                left: '40%',
            }}
        >
            <Grid item xs={6}>
                <Paper>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} padding={'20px'}>
                            <Typography variant="h6">Edit Registry</Typography>
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Name"
                                value={editName}
                                onChange={(e) => {
                                    setEditName(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="URL"
                                value={editUrl}
                                onChange={(e) => {
                                    setEditUrl(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Description"
                                value={editDescription}
                                onChange={(e) => {
                                    setEditDescription(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <InputLabel id="type-label-edit">Type</InputLabel>
                            <Select
                                value={editType}
                                onChange={(e) => {
                                    setEditType(e.target.value)
                                }}
                                labelId="type-label-edit"
                            >
                                <MenuItem value="ambient_registry" disabled>
                                    Ambient Registry
                                </MenuItem>
                                <MenuItem value="docker_hub">
                                    Docker Hub
                                </MenuItem>
                                <MenuItem value="aws_ecr">AWS ECR</MenuItem>
                                <MenuItem value="gcp_gcr">GCP GCR</MenuItem>
                                <MenuItem value="azure_acr">Azure ACR</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => handleSaveEditRegistry()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    )

    const addRegistryModal = (
        <Modal
            open={true}
            onClose={() => setShowAddRegistryModal(false)}
            style={{
                width: '50%',
                // position: 'absolute',
                top: '20%',
                left: '40%',
            }}
        >
            <Grid item xs={6}>
                <Paper>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        padding={'20px'}
                    >
                        <Grid item xs={12} padding={'20px'}>
                            <Typography variant="h6">Add Registry</Typography>
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Name"
                                value={addName}
                                onChange={(e) => {
                                    setAddName(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="URL"
                                value={addUrl}
                                onChange={(e) => {
                                    setAddUrl(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Description"
                                value={addDescription}
                                onChange={(e) => {
                                    setAddDescription(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="type-label-add">Type</InputLabel>
                            <Select
                                value={addType}
                                onChange={(e) => {
                                    setAddType(e.target.value)
                                }}
                                labelId="type-label-add"
                            >
                                <MenuItem value="ambient_registry">
                                    Ambient Registry
                                </MenuItem>
                                <MenuItem value="docker_hub">
                                    Docker Hub
                                </MenuItem>
                                <MenuItem value="aws_ecr">AWS ECR</MenuItem>
                                <MenuItem value="gcp_gcr">GCP GCR</MenuItem>
                                <MenuItem value="azure_acr">Azure ACR</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => handleSaveAddRegistry()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    )

    const addRegistryCredsModal = (
        <Modal
            open={true}
            onClose={() => setShowAddRegistryModal(false)}
            style={{
                width: '50%',
                // position: 'absolute',
                top: '20%',
                left: '40%',
            }}
        >
            <Grid item xs={6}>
                <Paper>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        padding={'20px'}
                    >
                        <Grid item xs={12} padding={'20px'}>
                            <Typography variant="h6">
                                Add Registry Credentials
                            </Typography>
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Username"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={'10px'}>
                            <TextField
                                label="Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => handleAddRegistryCreds()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    )

    const handleSaveAddRegistry = () => {
        console.log('Save add registry')
        const registry_service = new RegistryService(token)
        const data = {
            name: addName,
            url: addUrl,
            description: addDescription,
            registry_type: addType,
        }

        registry_service
            .postRegistry(data)
            .then((registry) => {
                console.log('Registry added: ' + registry)
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log('Error adding registry: ' + error)
            })

        setShowAddRegistryModal(false)
        setRefresh(!refresh)
    }

    const handleSaveEditRegistry = () => {
        console.log('Save edit registry')
        const registry_service = new RegistryService(token)
        if (!selectedRegistry) {
            console.log('Registry not found')
            return
        }

        const data = {
            name: editName,
            url: editUrl,
            description: editDescription,
            registry_type: editType,
        }

        registry_service
            .patchRegistry(selectedRegistry.id, data)
            .then((registry) => {
                console.log('Registry updated: ' + registry)
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log('Error updating registry: ' + error)
            })

        setShowEditRegistryModal(false)
        setRefresh(!refresh)
    }

    const handleAddRegistryCreds = () => {
        console.log('Add registry creds')

        const registry_service = new RegistryService(token)
        if (!selectedRegistry) {
            console.log('registry not selected')
            return
        }

        const data = {
            username: username,
            password: password,
        }

        registry_service
            .postRegistryAuth(selectedRegistry.id, data)
            .then((auth) => {
                console.log('Registry auth added: ' + auth)
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log('Error adding registry auth: ' + error)
            })

        setUsername('')
        setPassword('')
        setShowAddAuthCredsModal(false)
        setRefresh(!refresh)
    }

    const getNodeAuthStatus = (node: NodeWithRegistries) => {
        if (node.registry_associations) {
            const registry_association = node.registry_associations.find(
                (assoc) => assoc.registry_id === selectedRegistry?.id
            )
            if (registry_association) {
                return registry_association.status
            }
        }
        return 'Not Authenticated'
    }

    const getNodeError = (node: NodeWithRegistries) => {
        if (node.registry_associations) {
            const registry_association = node.registry_associations.find(
                (assoc) => assoc.registry_id === selectedRegistry?.id
            )
            if (registry_association) {
                return registry_association.error
            }
        }
        return ''
    }

    const nodesTable = (
        <Grid item xs={12} marginTop={2}>
            <Paper>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} padding={'20px'}>
                        <Typography variant="h6">Nodes</Typography>
                    </Grid>
                    <Grid item xs={12} padding={'15px'} marginBottom={2}>
                        {selectedRegistry && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>
                                            Authentication Status
                                        </TableCell>
                                        <TableCell>Error</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedNodes &&
                                        selectedNodes.map(
                                            (node: NodeWithRegistries) => (
                                                <TableRow key={node.id}>
                                                    <TableCell>
                                                        <Checkbox />
                                                    </TableCell>
                                                    <TableCell>
                                                        {node.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {node.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getNodeAuthStatus(
                                                            node
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getNodeError(node)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )

    const requestsTable = (
        <Grid item xs={12} marginTop={2}>
            <Paper>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} padding={'20px'}>
                        <Typography variant="h6">Requests</Typography>
                    </Grid>
                    <Grid item xs={12} padding={'15px'}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Requested</TableCell>
                                    <TableCell>Started</TableCell>
                                    <TableCell>Completed</TableCell>
                                    <TableCell>Failed</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Error</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requestsResponse &&
                                    requestsResponse.map((request: Request) => (
                                        <TableRow key={request.id}>
                                            <TableCell>{request.id}</TableCell>
                                            <TableCell>
                                                {request.status}
                                            </TableCell>
                                            <TableCell>
                                                {request.requested_ts}
                                            </TableCell>
                                            <TableCell>
                                                {request.started_ts}
                                            </TableCell>
                                            <TableCell>
                                                {request.completed_ts}
                                            </TableCell>
                                            <TableCell>
                                                {request.failed_ts}
                                            </TableCell>
                                            <TableCell>
                                                {request.notes}
                                            </TableCell>
                                            <TableCell>
                                                {request.error}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )

    return (
        <div>
            <TopToolBar />
            <Grid
                // container for the entire page
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: '10px' }}
            >
                {showEditRegistryModal && editRegistryModal}
                {showAddRegistryModal && addRegistryModal}
                {showAddAuthCredsModal && addRegistryCredsModal}
                {showAlert && alert}
                {showSnackBar && snackBar}
                <Grid
                    // item for the quick info panel
                    item
                    xs={12}
                >
                    <Paper>
                        <Grid
                            // container for the quick info panel
                            container
                            direction="column"
                            justifyContent="center"
                        >
                            <Grid
                                // item for the quick info panel title
                                // Container Repositories [space-between] [refresh icon, add icon]
                                item
                                // xs={12}
                                style={{ padding: '10px' }}
                            >
                                <Grid
                                    // container for the title
                                    container
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                >
                                    <Grid
                                        // item for the title
                                        item
                                        xs={8}
                                    >
                                        <Typography variant="h6">
                                            Container Registries
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        // item for the buttons
                                        item
                                        xs={4}
                                    >
                                        <IconButton
                                            style={{ float: 'right' }}
                                            onClick={() => setRefresh(!refresh)}
                                        >
                                            <RefreshIcon color="secondary" />
                                        </IconButton>
                                        <IconButton
                                            style={{ float: 'right' }}
                                            onClick={() =>
                                                setShowAddRegistryModal(true)
                                            }
                                        >
                                            <AddIcon color="secondary" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                                // item for info panel
                                item
                                xs={12}
                                style={{ padding: '10px' }}
                            >
                                <Typography>
                                    You have{' '}
                                    {registriesResponse
                                        ? registriesResponse.count
                                        : 0}{' '}
                                    registr
                                    {registriesResponse &&
                                    registriesResponse.count === 1
                                        ? 'y'
                                        : 'ies'}
                                    .
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid
                    // item for the table
                    item
                    xs={12}
                    margin={2}
                >
                    <Paper>{registriesTable}</Paper>
                </Grid>
                <Grid
                    // item for creds table
                    item
                    xs={12}
                    margin={2}
                >
                    {registryCredsTable}
                    {nodesTable}
                    {requestsTable}
                </Grid>
            </Grid>
        </div>
    )
}

export default RegistriesPage
