import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import { NodeService } from '../libs/ambient_api/NodeService'
import { CreateServiceRequest } from '../types/ApiRequests'
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Grid,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material'
import { Node } from '../types/TechnicalTypes'

interface EditNodePanelProps {
    // open: boolean // open state of the panel
    onClose: () => void
    node: Node
    token: string
}

const EditNodePanel: FC<EditNodePanelProps> = ({ onClose, node, token }) => {
    const theme = useTheme()
    const [name, setName] = useState<string>(node.name)
    const [description, setDescription] = useState<string>(node.description!)
    const [role, setRole] = useState<string>(node.role!)
    const [architecture, setArchitecture] = useState<string>(node.architecture)

    const handleSave = async () => {
        const nodeService = new NodeService()
        const updatedNode = {
            id: node.id,
            name: name,
            description: description,
        }
        await nodeService.patchNode(token, node.id, updatedNode)
        onClose()
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="edit-node-panel"
            aria-describedby="edit-node-panel"
        >
            <Grid
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.background.paper,
                    padding: theme.spacing(4),
                    width: 400,
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    style={{ marginBottom: theme.spacing(2) }}
                >
                    <Typography variant="h6">Edit Node</Typography>
                </Grid>
                <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Architecture"
                    value={architecture}
                    onChange={(e) => setArchitecture(e.target.value)}
                    margin="normal"
                />
                <Grid container direction="row" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default EditNodePanel
