import { Button, Grid, Typography, Paper, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import UserService from '../libs/ambient_api/UserService'
import Switch from '@mui/material/Switch'
import { UserPreferences } from '../types/User'
import { User } from '../types/User'
import { HomeButton } from '../components/HomeButton'
import { Divider } from '@mui/material'

const SettingsPage: React.FC = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState<string>('')
    const [user, setUser] = useState<User | null>(null)

    // auth useEffect
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (token) {
            setToken(token)
        } else {
            navigate('/home')
        }
    }, [navigate])

    // get user preferences
    useEffect(() => {
        if (!token) {
            return
        }
        const userService = new UserService(token)
        const fetchUser = async () => {
            const fetchedUser = await userService.getUserFromToken()
            if (!fetchedUser) {
                return
            }
            console.log('Fetched user:', fetchedUser)
            setUser(fetchedUser)
        }
        fetchUser()
    }, [token])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            style={{ font: 'sans' }}
            spacing={2}
        >
            <Grid item xs={8}>
                <HomeButton />
            </Grid>
            <Grid item xs={8}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h4" gutterBottom>
                        Account Info
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={8}>
                <Paper>
                    <Typography variant="h6" style={{ padding: '20px' }}>
                        Name: {user?.name}
                    </Typography>
                    <Divider />
                    <Typography variant="h6" style={{ padding: '20px' }}>
                        Email: {user?.email}
                    </Typography>
                    <Divider />
                    <Typography variant="h6" style={{ padding: '20px' }}>
                        ID: {user?.id}
                    </Typography>
                    <Typography variant="h6" style={{ padding: '20px' }}>
                        Username: {user?.username}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default SettingsPage
