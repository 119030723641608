import {
    Registry,
    RegistryAuth,
    NodeWithRegistries,
    Request,
} from '../../types/TechnicalTypes'
import { HttpError } from '../../types/Errors'
import BaseService from './BaseService'

import {
    CreateRegistryRequest,
    CreateRegistryAuthRequest,
} from '../../types/ApiRequests'

import {
    GetNodesForRegisryResponse,
    GetRequestsForRegisryResponse,
} from '../../types/ApiResponses'

export interface GetRegistriesResponse {
    timestamp: string
    count: number
    results: Registry[]
}

export interface GetRegistryCredsResponse {
    timestamp: string
    count: number
    results: RegistryAuth[]
}

export class RegistryService extends BaseService {
    public async getRegistries(): Promise<GetRegistriesResponse> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: GetRegistriesResponse = await response.json()
        return parsedResponse
    }

    public async addCredsToRegistry(
        registry_id: number,
        username: string,
        password: string
    ): Promise<RegistryAuth> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}/creds`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: RegistryAuth = await response.json()
        return parsedResponse
    }

    public async getRegistryAuths(
        registry_id: number
    ): Promise<GetRegistryCredsResponse> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}/creds`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: GetRegistryCredsResponse = await response.json()
        return parsedResponse
    }

    public async patchRegistry(
        registry_id: number,
        data: object
    ): Promise<Registry> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        } else {
            const parsedResponse: Registry = await response.json()
            return parsedResponse
        }
    }

    public async postRegistry(data: CreateRegistryRequest): Promise<Registry> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        } else {
            const parsedResponse: Registry = await response.json()
            return parsedResponse
        }
    }

    public async postRegistryAuth(
        registry_id: number,
        data: CreateRegistryAuthRequest
    ): Promise<RegistryAuth> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}/creds`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        } else {
            const parsedResponse: RegistryAuth = await response.json()
            return parsedResponse
        }
    }

    public async deleteRegistry(registry_id: number): Promise<void> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
    }

    public async deleteRegistryAuth(auth_id: number): Promise<void> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/creds/${auth_id}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
    }

    public async triggerRegistryAuthentication(
        creds_id: number
    ): Promise<string> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/creds/${creds_id}/trigger_auth`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const respJson = await response.json()
        const location = respJson.location
        return location
    }

    public async getNodesForRegistry(
        registry_id: number
    ): Promise<NodeWithRegistries[]> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}/nodes`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: GetNodesForRegisryResponse = await response.json()
        return parsedResponse.results
    }

    public async getRequestsForRegistry(
        registry_id: number
    ): Promise<Request[]> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/registries/${registry_id}/requests`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: GetRequestsForRegisryResponse =
            await response.json()
        return parsedResponse.results
    }
}
