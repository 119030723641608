import React from 'react'
import {
    Paper,
    Grid,
    Typography,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import UserService from '../libs/ambient_api/UserService'
import { useTheme, makeStyles } from '@mui/material/styles'

interface GenerateApiTokenPanelProps {
    apiToken: string | null
    setApiToken: (token: string) => void
    token: string
}

const GenerateApiTokenPanel: React.FC<GenerateApiTokenPanelProps> = ({
    apiToken,
    setApiToken,
    token,
}) => {
    const [copied, setCopied] = React.useState(false)
    const theme = useTheme()

    const handleCopy = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <Paper elevation={1}>
            <Grid container direction="column" justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ padding: '10px', paddingLeft: '20px' }}
                    >
                        Generate API Token
                    </Typography>
                </Grid>
                {/* If API token is set, we want to display it in an uneditable box with a copy option */}
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        margin={2}
                        justifyContent="space-between"
                    >
                        {apiToken && (
                            <Grid
                                item
                                xs={8}
                                style={{
                                    padding: '5px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    style={{
                                        padding: '10px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        backgroundColor: '#f0efed',
                                        display: 'flex',
                                        alignItems: 'center',
                                        background:
                                            theme.palette.background.paper,
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            variant="caption"
                                            align="center"
                                            color={'textSecondary'}
                                            style={{
                                                padding: '10px',
                                            }}
                                        >
                                            {apiToken.slice(0, 35) +
                                                '...' +
                                                apiToken.slice(-35)}
                                        </Typography>
                                        <CopyToClipboard
                                            text={apiToken}
                                            onCopy={handleCopy}
                                        >
                                            <Tooltip
                                                title={
                                                    copied
                                                        ? 'Copied!'
                                                        : 'Copy to clipboard'
                                                }
                                            >
                                                <IconButton
                                                    aria-label="copy"
                                                    style={{
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <FileCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item xs={4} style={{ padding: '5px' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={async () => {
                                    const user_svc = new UserService(token)
                                    const new_token =
                                        await user_svc.getAPIToken()
                                    if (!new_token) {
                                        console.log('No token')
                                        return
                                    }
                                    setApiToken(new_token)
                                }}
                            >
                                Generate Token
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default GenerateApiTokenPanel
