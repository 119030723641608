import { Box, CircularProgress, Typography, Paper } from '@mui/material'

export const LoadingScreen: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50vh',
                    width: '50vh',
                }}
            >
                <Typography variant="h5" align="center">
                    Loading...
                </Typography>
                <CircularProgress />
            </Paper>
        </Box>
    )
}
