import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ThemeContextProps {
    themeMode: 'light' | 'dark'
    toggleTheme: () => void
}

// Define a placeholder function that logs a warning
const defaultToggleTheme = () => {
    console.warn('toggleTheme function not implemented yet')
}

const ThemeContext = createContext<ThemeContextProps>({
    themeMode: 'light',
    toggleTheme: defaultToggleTheme, // Use the placeholder function
})

export const useTheme = () => useContext(ThemeContext)

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light')

    const toggleTheme = () => {
        setThemeMode((prevMode) => {
            const newMode = prevMode === 'light' ? 'dark' : 'light'
            console.log('Toggling theme to:', newMode)
            return newMode
        })
    }

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}
