class BaseService {
    protected token: string

    constructor(token: string) {
        // ensure token format
        token = token.replace('Bearer ', '')
        this.token = token
    }

    public getToken(): string {
        return this.token
    }
}

export default BaseService
