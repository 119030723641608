import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const RootPage: React.FC = () => {
    const { isAuthenticated } = useAuth0()
    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home')
        } else {
            navigate('/login')
        }
    }, [isAuthenticated]) // Re-run effect if authState changes

    return null
}

export default RootPage
