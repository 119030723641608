import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import {
    AppBar,
    Button,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Paper,
    Box,
    Chip,
} from '@mui/material'

const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const auth0 = useAuth0()

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

    useEffect(() => {
        if (auth0.isLoading) {
            return
        }
        // console.log('In auth0 useEffect')
        // console.log('isLoading: ', auth0.isLoading)
        // console.log('isAuthenticated: ', auth0.isAuthenticated)
        // console.log('user: ', auth0.user)
        // console.log('error: ', auth0.error)
        // console.log('API_URL: ', process.env.REACT_APP_API_URL)
        if (!auth0.isLoading && auth0.isAuthenticated) {
            setIsAuthenticated(true)
            navigate('/home')
        } else {
            setIsAuthenticated(false)
        }
    }, [auth0])

    const login = async () => {
        try {
            // console.log('Logging in...')
            const audience = process.env.REACT_APP_AUTH0_AUDIENCE
            // console.log('audience: ', audience)
            await auth0.loginWithPopup({
                authorizationParams: {
                    audience: audience,
                    scope: 'openid profile email offline_access',
                    metadata: {
                        appState: 'login',
                    },
                    redirect_uri: `${window.location.origin}/home`,
                    response_type: 'token id_token code',
                },
            })
        } catch (err) {
            // console.error('Error during login:', JSON.stringify(err, null, 2))
        }
    }

    const signup = async () => {
        try {
            // console.log('Signing up...')
            const audience = process.env.REACT_APP_AUTH0_AUDIENCE
            // console.log('audience: ', audience)
            await auth0.loginWithRedirect({
                authorizationParams: {
                    audience: audience,
                    scope: 'openid profile email offline_access',
                    metadata: {
                        appState: 'signup',
                    },
                    redirect_uri: `${window.location.origin}/signup`,
                    response_type: 'token id_token code',
                },
            })
        } catch (err) {
            // console.error('Error during signup:', JSON.stringify(err, null, 2))
        }
    }

    return (
        // Refactor to use MUI components
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100vh' }}
        >
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    width: '400px',
                    height: '400px',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                >
                    <Typography variant="h4" style={{ alignItems: 'center' }}>
                        Welcome to Ambient
                    </Typography>
                    <Typography variant="h6" style={{ alignItems: 'center' }}>
                        Please login to continue
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                >
                    <Chip
                        label="Login"
                        color="primary"
                        style={{ marginTop: '20px', margin: '10px' }}
                        onClick={login}
                    />
                    <Chip
                        label="Sign Up"
                        color="secondary"
                        onClick={signup}
                        style={{ marginTop: '20px', margin: '10px' }}
                    />
                </Grid>
            </Paper>
        </Grid>
    )
}

export default LoginPage
