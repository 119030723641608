import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import { NodeService, NodeOrder } from '../libs/ambient_api/NodeService'
import { CreateServiceRequest } from '../types/ApiRequests'
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Grid,
    Box,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material'

interface AddApplicationPanelProps {
    onClose: () => void
    token: string
}

const AddApplicationPanel: FC<AddApplicationPanelProps> = ({
    onClose,
    token,
}) => {
    const theme = useTheme()
    const [nodeIds, setNodeIds] = useState<number[]>([])
    const [nodes, setNodes] = useState<any[]>([])
    const nodeService = new NodeService()
    const [existingNameWarning, setExistingNameWarning] = useState(false)

    // fetch nodes
    useEffect(() => {
        const fetchNodes = async () => {
            const nodes = await nodeService.getNodes(
                token,
                'timestamp',
                NodeOrder.desc,
                null
            )
            setNodes(nodes)
            const nodeIds = nodes.map((node) => node.id)
            setNodeIds(nodeIds)
        }
        fetchNodes()
    }, [token])

    const getNodeById = (id: number) => {
        return nodes.find((node) => node.id === id)
    }

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        node_ids: [0],

        image: '',
        tags: '',
        ports: '',
        replicas: 1,
        labels: '',
        env_vars: '',
        hostname: '',
        mounts: '',
        networks: '',
    })
    const [showWaitingScreen, setShowWaitingScreen] = useState(false)
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)
    const [showErrorScreen, setShowErrorScreen] = useState(false)

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value as string,
            })
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // check name available via app service
        const name = e.target.value
        const fetchData = async () => {
            const app_service = new ApplicationService(token)
            const available = await app_service.checkAppNameAvailability(name)
            if (!available) {
                setExistingNameWarning(true)
            } else {
                setExistingNameWarning(false)
            }
        }
        fetchData()
        setFormData({
            ...formData,
            name: e.target.value,
        })
    }

    // handle node ids change with select change event
    // const handleNodeIdsChange = (e: SelectChangeEvent) => {
    //     setFormData({
    //         ...formData,
    //         node_ids: e.target.value as unknown as number[],
    //     })
    // }
    const handleNodeIdsChange = (
        e: SelectChangeEvent<typeof formData.node_ids>
    ) => {
        const { value } = e.target
        const node_ids =
            typeof value === 'string' ? [Number(value)] : value.map(Number)
        // remove 0 from node_ids
        const filtered_node_ids = node_ids.filter((id) => id !== 0)
        setFormData({
            ...formData,
            node_ids: filtered_node_ids,
        })
    }

    const validate = (): boolean => {
        if (
            !formData.name ||
            !formData.description ||
            !formData.image ||
            !formData.node_ids
            // !formData.tags
        ) {
            alert('All fields are required')
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        // validate form
        // console.log('Validating form...')
        if (!validate()) {
            return
        }

        // show waiting screen
        setShowWaitingScreen(true)

        // create service
        try {
            const requestBody: CreateServiceRequest = {
                name: formData.name,
                description: formData.description,
                node_ids: formData.node_ids,
                requested_service_spec: {
                    image: formData.image,
                    ports: parseListString(formData.ports),
                    tags: parseListString(formData.tags),
                    replicas: formData.replicas,
                    labels: parseListString(formData.labels),
                    env_vars: parseListString(formData.env_vars),
                    hostname: formData.hostname,
                    mounts: parseListString(formData.mounts),
                    networks: parseListString(formData.networks),
                },
            }
            const app_service = new ApplicationService(token)
            const response = await app_service.createService(requestBody)
            // console.log('Response: ' + response)

            // hide waiting screen
            setShowWaitingScreen(false)

            // show success screen
            setShowSuccessScreen(true)
        } catch (error: any) {
            console.error('AddApplicationPanel.handleSubmit: ' + error)
            setShowWaitingScreen(false)
            setShowErrorScreen(true)
        }
    }

    function parseListString(input: string): string[] {
        console.log('AddApplicationPanel parseListString input:', input)
        if (!input) {
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
        try {
            console.log(
                'AddApplicationPanel parseListString returning parsed array'
            )
            return input.split(',').map((s) => s.trim())
        } catch (e) {
            console.log('AddApplicationPanel parseListString error:', e)
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
    }

    function parseListNumber(input: string): number[] {
        console.log('AddApplicationPanel parseListNumber input:', input)
        if (!input) {
            console.log(
                'AddApplicationPanel parseListNumber returning empty array'
            )
            return []
        }
        try {
            console.log(
                'AddApplicationPanel parseListNumber returning parsed array'
            )
            return input.split(',').map((s) => parseInt(s.trim()))
        } catch (e) {
            console.log('AddApplicationPanel parseListNumber error:', e)
            console.log(
                'AddApplicationPanel parseListNumber returning empty array'
            )
            return []
        }
    }

    const waitingScreen = (
        <Modal
            open={true}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="button" align="center" gutterBottom>
                    Launching application...
                </Typography>
            </div>
        </Modal>
    )

    const successScreen = (
        <Modal
            open={true}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <Grid
                container
                direction={'column'}
                alignItems={'center'}
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Box m={2} />
                <Typography variant="h6" align="center" gutterBottom>
                    🎉 Application deployed! 🎉
                </Typography>
                <Box m={2} />
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </Grid>
        </Modal>
    )

    const errorScreen = (
        <Modal
            open={true}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <Grid
                container
                direction={'column'}
                alignItems={'center'}
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Box m={2} />
                <Typography variant="h6" align="center" gutterBottom>
                    🚨 Error deploying application! 🚨
                </Typography>
                <Box m={2} />
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </Grid>
        </Modal>
    )

    return ReactDOM.createPortal(
        <Modal
            open={true}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <Grid
                container
                direction={'row'}
                justifyContent={'center'}
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Launch App
                </Typography>
                <form>
                    <Grid
                        container
                        style={{ padding: '20px' }}
                        justifyContent={'center'}
                    >
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleNameChange}
                                error={existingNameWarning}
                                helperText={
                                    existingNameWarning
                                        ? 'Name already exists'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Image"
                                name="image"
                                value={formData.image}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Ports"
                                name="ports"
                                // type="array"
                                value={formData.ports}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Tags"
                                name="tags"
                                value={formData.tags}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Replicas"
                                name="replicas"
                                value={formData.replicas}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Labels"
                                name="labels"
                                value={formData.labels}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Environment Variables"
                                name="env_vars"
                                value={formData.env_vars}
                                onChange={handleChange}
                                helperText="KEY1=value1,KEY2=value2"
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Hostname"
                                name="hostname"
                                value={formData.hostname}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Mounts"
                                name="mounts"
                                value={formData.mounts}
                                onChange={handleChange}
                                helperText="source:target:options"
                            />
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <TextField
                                label="Networks"
                                name="networks"
                                value={formData.networks}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid
                            container
                            justifyContent={'center'}
                            style={{ padding: '5px' }}
                            direction={'column'}
                        >
                            <InputLabel id="nodes-label">Nodes</InputLabel>
                            <Select
                                labelId="nodes-label"
                                id="node-ids"
                                name="node_ids"
                                multiple
                                value={formData.node_ids}
                                onChange={handleNodeIdsChange}
                            >
                                {nodeIds.map((nodeId) => (
                                    <MenuItem key={nodeId} value={nodeId}>
                                        <Typography>
                                            {getNodeById(nodeId).name} [ID:{' '}
                                            {nodeId}] - [
                                            {getNodeById(nodeId).role}] -{' '}
                                            <Typography
                                                style={{ font: 'caption' }}
                                            >
                                                {
                                                    getNodeById(nodeId)
                                                        .description
                                                }
                                            </Typography>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid
                            container
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                // padding: '10px',
                                paddingTop: '50px',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                style={{ marginRight: '10px' }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {showWaitingScreen && waitingScreen}
                {showSuccessScreen && successScreen}
                {showErrorScreen && errorScreen}
            </Grid>
        </Modal>,
        document.getElementById('add-application-portal-root')!
    )
}

export default AddApplicationPanel
