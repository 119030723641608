import React, { useRef, useEffect, useState, useImperativeHandle } from 'react'
import { Terminal as XTerm } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { WebLinksAddon } from 'xterm-addon-web-links'
import 'xterm/css/xterm.css'

interface TerminalProps {
    handleSubmit?: (command: string, xterm: XTerm | null) => void
    username: string
    hostname: string
}

const Terminal = React.forwardRef((props: TerminalProps, ref) => {
    const [commandBuffer, setCommandBuffer] = useState<string>('')
    const terminalRef = useRef<HTMLDivElement>(null)
    const xterm = useRef<XTerm | null>(null)
    const fitAddon = new FitAddon()
    const webLinksAddon = new WebLinksAddon()
    const commandBufferRef = useRef<string>('')

    const writePrompt = () => {
        xterm.current?.write(`${props.username}@${props.hostname}: `)
    }

    // useEffect(() => {
    //     // logging for key press debugging
    //     console.log('Updated command buffer:', commandBuffer);
    // }, [commandBuffer]);

    useEffect(() => {
        if (terminalRef.current) {
            xterm.current = new XTerm({
                cursorBlink: true,
            })

            xterm.current.loadAddon(fitAddon)
            xterm.current.loadAddon(webLinksAddon)
            xterm.current.open(terminalRef.current)
            fitAddon.fit()

            xterm.current.onData((data) => {
                let newCommandBuffer = commandBuffer // Start with the current commandBuffer

                if (data === '\r' || data === '\n') {
                    // console.log('Enter pressed')
                    props.handleSubmit?.(
                        commandBufferRef.current,
                        xterm.current
                    ) // Use the current value of the ref
                    setCommandBuffer('')
                    xterm.current?.write(
                        `\r\n${props.username}@${props.hostname}: `
                    ) // Go to a new line
                    // writePrompt(); // Write the prompt on the new line
                } else if (data === '\x7f') {
                    // console.log('Backspace pressed')
                    newCommandBuffer = newCommandBuffer.slice(0, -1)
                    setCommandBuffer((prev) => {
                        const newBuffer = prev.slice(0, -1)
                        commandBufferRef.current = newBuffer // Update the ref
                        return newBuffer
                    })
                    xterm.current?.write('\b \b') // Backspace
                } else {
                    newCommandBuffer += data
                    setCommandBuffer((prev) => {
                        const newBuffer = prev + data
                        commandBufferRef.current = newBuffer // Update the ref
                        return newBuffer
                    })
                    xterm.current?.write(data)
                }
            })

            writePrompt() // Write the prompt initially
        }

        return () => {
            xterm.current?.dispose()
        }
    }, [])

    const write = (text: string) => {
        xterm.current?.write(text)
    }

    useImperativeHandle(ref, () => ({
        write,
        writePrompt,
    }))

    // return <div ref={terminalRef} className="w-full h-full" />
    return (
        <div
            ref={terminalRef}
            style={{
                maxHeight: '100px',
            }}
        />
    )
})

export default Terminal
