import React, { useState, useEffect, useRef } from 'react'
import Terminal from './Terminal'
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Paper,
} from '@mui/material'
import { NodeService, NodeOrder } from '../libs/ambient_api/NodeService'
import { User } from '../types/User'
import UserService from '../libs/ambient_api/UserService'
import { WebSocketCommandMessage } from '../types/TechnicalTypes'
import { useAuth0 } from '@auth0/auth0-react'

interface NodeTerminalPanelProps {
    handleClose: () => void
}

const NodeTerminalPanel: React.FC<NodeTerminalPanelProps> = ({
    handleClose,
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [selectedNode, setSelectedNode] = useState<string>('')
    const [nodes, setNodes] = useState<any[]>([])
    const [showNodeForm, setShowNodeForm] = useState<boolean>(true)
    const [showTerminal, setShowTerminal] = useState<boolean>(false)
    // const [ws, setWs] = useState<WebSocket | null>(null);
    const wsRef = useRef<WebSocket | null>(null)
    const [user, setUser] = useState<User | null>(null)
    const terminalRef = useRef<any>(null)

    // create websocket
    useEffect(() => {
        if (!showTerminal) return

        const terminalSessionId = localStorage.getItem('terminalSessionId')
        const token = getAccessTokenSilently()
        const ownerId = localStorage.getItem('ownerId')

        if (!terminalSessionId || !token || !ownerId) {
            return
        }

        // const url = 'wss://3jl9eq8bkl.execute-api.us-east-1.amazonaws.com/dev/';
        const url = 'wss://appapi-esbgchka2a-uc.a.run.app/'
        // const queryParams = `?session_id=${terminalSessionId}&source=user`;
        let queryParams = `?session_id=${terminalSessionId}`
        queryParams += `&source=user`
        queryParams += `&token=${token}`
        queryParams += `&node_id=${selectedNode}`
        // console.log('Full URL: ', url + queryParams)

        // const socket = new WebSocket(url + queryParams);
        const socket = new WebSocket(url + queryParams)

        socket.onopen = () => {
            // console.log('Socket opened')
        }
        wsRef.current = socket
        socket.onmessage = (event) => {
            // console.log('Received message:', event)
            const data = event.data
            // console.log('Received data:', data)
            // detect whether data is JSON or string
            // if string, try to parse it as JSON, if it fails, print it
            // if JSON, parse it and print it
            if (typeof data === 'string') {
                try {
                    const dataJson = JSON.parse(data)
                    // console.log('Data JSON:', dataJson)
                    if (dataJson.message === 'Internal server error') {
                        terminalRef.current?.write(
                            '\n\rInternal server error\n\r'
                        )
                        terminalRef.current?.writePrompt()
                        return
                    }
                } catch (error) {
                    // console.log('Error parsing to json:', error)
                    terminalRef.current?.write('\n\r' + data + '\n\r')
                    terminalRef.current?.writePrompt()
                    return
                }
            }
            const dataJson = JSON.parse(data)
            // console.log('Data JSON:', dataJson)
            // console.log('Data JSON error:', dataJson.error)
            // console.log('Data JSON response:', dataJson.response)
            if (dataJson.error) {
                terminalRef.current?.write(
                    '\n\rERROR: ' + dataJson.error + '\n\r'
                )
            }
            if (dataJson.response) {
                // console.log('Response:', dataJson.response)
                let response_string = dataJson.response as string
                // replace \n with \n\r
                response_string = response_string.replace(/\n/g, '\n\r')
                terminalRef.current?.write('\n\r' + response_string + '\n\r')
            }

            terminalRef.current?.writePrompt()
        }
        socket.onerror = (error) => {
            // console.error('WebSocket Error:', error)
        }
        socket.onclose = (event) => {
            if (event.wasClean) {
                // console.log(
                //     `Closed cleanly, code=${event.code}, reason=${event.reason}`
                // )
            } else {
                // console.error('Connection died')
            }
        }
        // console.log('Created websocket:', socket)
    }, [showTerminal])

    // send data to websocket
    const handleCommandSubmit = async (command: string) => {
        // console.log('Sending command:', command)
        if (!command) {
            // console.log('No command')
            return
        }

        // console.log('Websocket:', wsRef.current)
        if (wsRef.current === null) {
            // console.log('No websocket')
            return
        }

        const session_id = localStorage.getItem('terminalSessionId')
        // console.log('Session id:', session_id)
        if (!session_id) {
            // console.log('No session id')
            return
        }
        const message: WebSocketCommandMessage = {
            session_id: session_id as string,
            source: 'user',
            command: command,
        }
        // console.log('Message:', message)

        const msgString = JSON.stringify(message)
        // console.log('Message string:', msgString)
        wsRef.current.send(msgString)
        // console.log('Sent command')
    }

    // fetch nodes from backend
    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently()
            if (!token) {
                return
            }
            const node_service = new NodeService()
            const nodes = await node_service.getNodes(
                token,
                'timestamp',
                NodeOrder.desc,
                null
            )
            setNodes(nodes)
        }
        fetchData()
    }, [])

    const handleSubmit = async () => {
        // console.log('Selected node:', selectedNode)
        if (!selectedNode) {
            alert('Please select a node.')
            return
        }

        const token = await getAccessTokenSilently()
        const ownerId = localStorage.getItem('ownerId')
        if (!ownerId || !token) {
            alert('Please log in first.')
            return
        }

        const node_service = new NodeService()
        const terminalSession = await node_service.requestTerminalSession(
            token,
            ownerId,
            selectedNode as string
        )
        // console.log('Terminal session:', terminalSession)
        const terminalSessionIdStr = String(terminalSession.id)
        localStorage.setItem('terminalSessionId', terminalSessionIdStr)

        setShowNodeForm(false)
        setShowTerminal(true)
    }

    const chooseNodeForm = (
        <div>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="node-select-label">Node</InputLabel>
                <Select
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        // getContentAnchorEl: null,
                    }}
                    labelId="node-select-label"
                    value={selectedNode}
                    onChange={(e) => setSelectedNode(e.target.value as string)}
                    label="Node"
                >
                    {nodes.map((node) => (
                        <MenuItem key={node.id} value={node.id}>
                            {node.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    )

    const terminalComponent = (
        <Paper>
            <Terminal
                ref={terminalRef}
                handleSubmit={handleCommandSubmit}
                username="admin"
                hostname={selectedNode || ''}
            />
        </Paper>
    )

    return (
        <Modal
            open={true}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <Paper
                elevation={3}
                style={{
                    backgroundColor: '#f5f5f5',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                {showNodeForm && chooseNodeForm}
                {showTerminal && terminalComponent}
                <Button
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={handleClose}
                >
                    X
                </Button>
            </Paper>
        </Modal>
    )
}

export default NodeTerminalPanel
