import { useEffect, useState, useCallback, useMemo } from 'react'
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import {
    Grid,
    Paper,
    Typography,
    Card,
    Box,
    Button,
    Chip,
    Fab,
    IconButton,
    Tooltip,
} from '@mui/material'
import { ClusterService } from '../libs/ambient_api/ClusterService'
import { NodeService } from '../libs/ambient_api/NodeService'
import TopToolBar from '../components/AppToolBar'
import { Cluster, Node } from '../types/TechnicalTypes'
import {
    customDarkPallete,
    customLightPallete,
    darkTheme,
    lightTheme,
} from '../components/Themes'
import { useTheme } from '../ThemeContext'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import AddIcon from '@mui/icons-material/Add'
import AddNodePanel from '../components/AddNodePanel'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import EditIcon from '@mui/icons-material/Edit'
import { EditClusterPanel } from '../components/EditClusterPanel'
import AddClusterPanel from '../components/AddClusterPanel'

interface DraggableItemProps {
    id: number
    children: React.ReactNode
}

interface DroppableAreaProps {
    id: number | string
    children: React.ReactNode
}

// Custom draggable component
const DraggableItem: React.FC<DraggableItemProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: String(id),
    })
    const style = {
        transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
            : undefined,
    }

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {children}
        </div>
    )
}

// Custom droppable component
const DroppableArea: React.FC<DroppableAreaProps> = ({ id, children }) => {
    const { setNodeRef } = useDroppable({ id: String(id) })
    return <div ref={setNodeRef}>{children}</div>
}

// Special droppable component for unassigning nodes
const UnassignDroppable: React.FC = () => {
    const { setNodeRef, isOver } = useDroppable({ id: 'unassign' })
    return (
        <div ref={setNodeRef}>
            <Box
                style={{
                    padding: '20px',
                    margin: '20px',
                    paddingRight: '90px',
                    paddingLeft: '90px',
                    backgroundColor: isOver
                        ? customDarkPallete.clusterDragAndDrop.node.active
                        : customDarkPallete.clusterDragAndDrop.node.inactive,
                    opacity: isOver ? 1 : 0.5,
                }}
            >
                <Grid container direction={'row'}>
                    <ContentCutIcon style={{ margin: '10px' }} />
                    <Typography style={{ padding: '10px' }}>
                        Unassign Node
                    </Typography>
                </Grid>
            </Box>
        </div>
    )
}

const ClustersPage: React.FC = () => {
    const [refresh, setRefresh] = useState<boolean>(false)
    const [clusters, setClusters] = useState<Cluster[]>([])
    const [nodes, setNodes] = useState<Node[]>([])
    const [showAddNodePanel, setShowAddNodePanel] = useState<boolean>(false)
    const themeMode = useTheme()
    const customPallete =
        themeMode.themeMode === 'dark' ? customDarkPallete : customLightPallete
    const theme = themeMode.themeMode === 'dark' ? darkTheme : lightTheme
    const [changesMade, setChangesMade] = useState<boolean>(false)
    const nodeService = new NodeService()
    const [openCancelConfirm, setOpenCancelConfirm] = useState<boolean>(false)
    const [openSaveConfirm, setOpenSaveConfirm] = useState<boolean>(false)
    const [openEditClusterPanel, setOpenEditClusterPanel] =
        useState<boolean>(false)
    const [clusterSelections, setClusterSelections] = useState<number[]>([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [showAddClusterPanel, setShowAddClusterPanel] =
        useState<boolean>(false)

    useEffect(() => {
        const fetchClusters = async () => {
            const token = localStorage.getItem('access_token')
            if (!token) {
                console.error('No token found')
                return
            }
            const clusterService = new ClusterService(token)
            const fetchedClusters = await clusterService.getClusters()
            setClusters(fetchedClusters)
        }

        const fetchNodes = async () => {
            const token = localStorage.getItem('access_token')
            if (!token) {
                console.error('No token found')
                return
            }
            const nodeService = new NodeService()
            const fetchedNodes = await nodeService.getNodes(token)
            setNodes(fetchedNodes)
        }

        fetchClusters()
        fetchNodes()
    }, [refresh])

    const handleRefresh = useCallback(() => {
        setRefresh((prev) => !prev)
        setChangesMade(false)
        setOpenCancelConfirm(false)
    }, [])

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event
        const nodeId = Number(active.id)

        // If the node is dropped in the special "Unassign" circle, set cluster_id to null
        if (over?.id === 'unassign') {
            setNodes((prevNodes) =>
                prevNodes.map((node) =>
                    node.id === nodeId ? { ...node, cluster_id: null } : node
                )
            )
            setChangesMade(true)
            return
        }

        // Otherwise, assign it to the cluster it was dropped on
        if (over) {
            const newClusterId = Number(over.id)

            setNodes((prevNodes) =>
                prevNodes.map((node) =>
                    node.id === nodeId
                        ? { ...node, cluster_id: newClusterId }
                        : node
                )
            )
        }

        // set changesMade flag to true
        setChangesMade(true)
    }, [])

    const unassignedNodes = useMemo(
        () => nodes.filter((node) => !node.cluster_id),
        [nodes]
    )

    const doesClusterHaveNodes = (cluster_id: number) => {
        return nodes.some((node) => node.cluster_id === cluster_id)
    }

    const addNewNodeButton = (
        <Button
            startIcon={<AddIcon />}
            onClick={() => setShowAddNodePanel(true)}
            style={{
                fontSize: '10px',
                padding: '20px',
            }}
        >
            Create New Node
        </Button>
    )
    const renderUnassignedDraggables = useCallback(
        () =>
            unassignedNodes.map((node) => (
                <DraggableItem key={node.id} id={node.id}>
                    <Card
                        style={{
                            padding: '20px',
                            margin: '20px',
                            background:
                                customPallete.clusterDragAndDrop.node.inactive,
                        }}
                    >
                        <Typography
                            style={{
                                top: '5px',
                                right: '5px',
                                padding: '5px',
                                color: theme.palette.text.secondary,
                            }}
                            fontSize={'10px'}
                        >
                            Node
                        </Typography>
                        <Typography>{node.name}</Typography>
                        <Typography
                            style={{
                                top: '5px',
                                right: '5px',
                                padding: '5px',
                                color: theme.palette.text.secondary,
                            }}
                            fontSize={'10px'}
                        >
                            Status: {node.status}
                        </Typography>
                    </Card>
                </DraggableItem>
            )),
        [unassignedNodes]
    )

    const renderClusterDroppables = useCallback(
        () =>
            clusters.map((cluster) => {
                const clusterNodes = nodes.filter(
                    (node) => node.cluster_id === cluster.id
                )

                return (
                    <DroppableArea key={cluster.id} id={cluster.id}>
                        <Card
                            style={{
                                padding: '20px',
                                margin: '20px',
                                minWidth: '200px',
                                backgroundColor: doesClusterHaveNodes(
                                    cluster.id
                                )
                                    ? customPallete.clusterDragAndDrop.cluster
                                          .active
                                    : customPallete.clusterDragAndDrop.cluster
                                          .inactive,
                            }}
                        >
                            <Typography
                                style={{
                                    top: '5px',
                                    right: '5px',
                                    padding: '5px',
                                    color: doesClusterHaveNodes(cluster.id)
                                        ? theme.palette.text.primary
                                        : theme.palette.text.secondary,
                                }}
                                fontSize={'10px'}
                            >
                                Cluster
                            </Typography>
                            <Typography
                                style={{
                                    padding: '10px',
                                    color: doesClusterHaveNodes(cluster.id)
                                        ? theme.palette.text.primary
                                        : theme.palette.text.secondary,
                                }}
                            >
                                {cluster.name}
                            </Typography>
                            {!doesClusterHaveNodes(cluster.id) && (
                                <Box>
                                    <Grid container direction={'row'}>
                                        <ZoomOutMapIcon
                                            style={{
                                                margin: '10px',
                                                color: theme.palette.text
                                                    .secondary,
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                padding: '10px',
                                                color: theme.palette.text
                                                    .secondary,
                                            }}
                                        >
                                            Drag and drop nodes here
                                        </Typography>
                                    </Grid>
                                    {addNewNodeButton}
                                </Box>
                            )}
                            {clusterNodes.map((node) => (
                                <DraggableItem key={node.id} id={node.id}>
                                    <Card
                                        style={{
                                            padding: '10px',
                                            margin: '10px',
                                            backgroundColor:
                                                doesClusterHaveNodes(cluster.id)
                                                    ? customPallete
                                                          .clusterDragAndDrop
                                                          .node.active
                                                    : customPallete
                                                          .clusterDragAndDrop
                                                          .node.inactive,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                top: '5px',
                                                right: '5px',
                                                padding: '5px',
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                            fontSize={'10px'}
                                        >
                                            Node
                                        </Typography>
                                        <Typography>{node.name}</Typography>
                                        <Typography
                                            style={{
                                                top: '5px',
                                                right: '5px',
                                                padding: '5px',
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                            fontSize={'10px'}
                                        >
                                            Status: {node.status}
                                        </Typography>
                                    </Card>
                                </DraggableItem>
                            ))}
                            {cluster.tags.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={`# ${tag}`}
                                    style={{ margin: '5px' }}
                                />
                            ))}
                        </Card>
                    </DroppableArea>
                )
            }),
        [clusters, nodes]
    )

    const handleAddNodePanelClose = () => {
        setShowAddNodePanel(false)
    }

    const handleSaveChanges = () => {
        // run a PATCH for each node
        const token = localStorage.getItem('access_token')
        nodes.forEach(async (node) => {
            await nodeService.putNode(token!, node.id, node)
        })
        const wait = (ms: number) =>
            new Promise((resolve) => setTimeout(resolve, ms))
        wait(1000).then(() => handleRefresh())
        setOpenSaveConfirm(false)
    }

    const confirmCancelDialog = (
        <Dialog
            open={openCancelConfirm}
            onClose={() => setOpenCancelConfirm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Discard Changes?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to discard changes?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenCancelConfirm(false)}>
                    Cancel
                </Button>
                <Button onClick={handleRefresh}>Discard</Button>
            </DialogActions>
        </Dialog>
    )

    const confirmSaveDialog = (
        <Dialog
            open={openSaveConfirm}
            onClose={() => setOpenSaveConfirm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Save Changes?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to save changes?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenSaveConfirm(false)}>
                    Cancel
                </Button>
                <Button onClick={handleSaveChanges}>Save</Button>
            </DialogActions>
        </Dialog>
    )

    const handleClusterSelection = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLInputElement
        const clusterId = Number(target.id) // Use cluster ID directly
        console.log('Cluster ID:', clusterId)

        if (clusterSelections.includes(clusterId)) {
            setClusterSelections((prev) =>
                prev.filter((id) => id !== clusterId)
            )
        } else {
            setClusterSelections((prev) => [...prev, clusterId])
        }
        console.log(clusterSelections)
    }

    const clustersTable = (
        <Paper
            elevation={0}
            style={{
                padding: '20px',
                margin: '20px',
                minWidth: '600px',
                minHeight: '200px',
            }}
        >
            <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent={'space-between'}
                    >
                        <Grid item>
                            <Typography
                                variant="h6"
                                padding={2}
                                paddingLeft={3}
                                paddingBottom={4}
                            >
                                Clusters Table
                            </Typography>
                        </Grid>
                        <Grid item padding={'10px'}>
                            <IconButton onClick={handleRefresh}>
                                <RefreshIcon color="primary" />
                            </IconButton>
                            <IconButton
                                onClick={() => setShowAddClusterPanel(true)}
                            >
                                <AddIcon color="primary" />
                            </IconButton>
                            {clusterSelections.length > 0 && (
                                <IconButton
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    <DeleteIcon color="primary" />
                                </IconButton>
                            )}
                            {clusterSelections.length === 1 && (
                                <IconButton
                                    onClick={() =>
                                        setOpenEditClusterPanel(true)
                                    }
                                >
                                    <EditIcon color="primary" />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'select all clusters',
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel>Cluster Name</TableSortLabel>
                            </TableCell>
                            <TableCell>Nodes</TableCell>
                            <TableCell>Tags</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clusters.map((cluster) => (
                            <TableRow key={cluster.id}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{
                                            'aria-label': 'select cluster',
                                        }}
                                        onClick={handleClusterSelection}
                                        id={cluster.id.toString()}
                                    />
                                </TableCell>
                                <TableCell>{cluster.name}</TableCell>
                                <TableCell>
                                    {
                                        nodes.filter(
                                            (node) =>
                                                node.cluster_id === cluster.id
                                        ).length
                                    }
                                </TableCell>
                                <TableCell>
                                    {cluster.tags.map((tag) => (
                                        <Chip key={tag} label={`# ${tag}`} />
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

    const renderEditClusterPanel = () => {
        if (clusterSelections.length === 1) {
            const selectedCluster = clusters.find(
                (cluster) => cluster.id === clusterSelections[0]
            ) // Find the cluster by ID

            if (selectedCluster) {
                return (
                    <EditClusterPanel
                        open={openEditClusterPanel}
                        onClose={() => setOpenEditClusterPanel(false)}
                        token={localStorage.getItem('access_token')!}
                        cluster={selectedCluster} // Pass the correct cluster
                    />
                )
            }
        }
        return null // Return null if no cluster is selected or not found
    }

    const handleClustersDelete = () => {
        // Run a DELETE for each cluster
        const token = localStorage.getItem('access_token')
        clusterSelections.forEach(async (clusterId) => {
            await new ClusterService(token!).deleteCluster(clusterId)
        })
        const wait = (ms: number) =>
            new Promise((resolve) => setTimeout(resolve, ms))
        wait(1000).then(() => handleRefresh())
        setOpenDeleteDialog(false)
    }

    const clustersDeleteDialog = (
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Delete Clusters?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the following selected
                    clusters?
                </DialogContentText>
                <DialogContentText>
                    {clusterSelections.map((clusterId) => (
                        <Chip key={clusterId} label={`${clusterId}`} />
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button onClick={handleClustersDelete}>Delete</Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <Box>
            <TopToolBar />
            {showAddNodePanel && (
                <AddNodePanel
                    onClose={handleAddNodePanelClose}
                    token={localStorage.getItem('access_token')!}
                />
            )}
            {confirmCancelDialog}
            {confirmSaveDialog}
            {clustersDeleteDialog}
            {showAddClusterPanel && (
                <AddClusterPanel
                    onClose={() => setShowAddClusterPanel(false)}
                    token={localStorage.getItem('access_token')!}
                />
            )}
            {renderEditClusterPanel()}
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={10}>
                    {clustersTable}
                </Grid>
                <Grid item xs={10}>
                    <Paper
                        elevation={0}
                        style={{
                            padding: '20px',
                            margin: '20px',
                            minWidth: '600px',
                            minHeight: '400px',
                        }}
                    >
                        <Grid
                            container
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    padding={2}
                                    paddingLeft={3}
                                    paddingBottom={4}
                                >
                                    Clusters
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={() =>
                                        !changesMade
                                            ? handleRefresh()
                                            : setOpenCancelConfirm(true)
                                    }
                                >
                                    <RefreshIcon color="primary" />
                                </IconButton>
                                <IconButton
                                    onClick={() => setShowAddNodePanel(true)}
                                >
                                    <AddIcon color="primary" />
                                </IconButton>
                                {changesMade && (
                                    <Box>
                                        <Tooltip title="Save Changes">
                                            <IconButton
                                                onClick={() =>
                                                    setOpenSaveConfirm(true)
                                                }
                                            >
                                                <CheckIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Discard Changes">
                                            <IconButton
                                                onClick={() =>
                                                    setOpenCancelConfirm(true)
                                                }
                                            >
                                                <CloseIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        <DndContext
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Grid container direction={'row'}>
                                        {renderUnassignedDraggables()}
                                        {renderClusterDroppables()}
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <UnassignDroppable />{' '}
                                </Grid>
                            </Grid>
                        </DndContext>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ClustersPage
