import { User } from '../../types/User'
import { Organization } from '../../types/Organization'
import { HttpError } from '../../types/Errors'
import BaseService from './BaseService'

const apiUrl = process.env.REACT_APP_API_URL

interface UsersLoginResponse {
    user: User
    org: Organization
}

class UserService extends BaseService {
    public async whoami(): Promise<User> {
        const response = await fetch(apiUrl + '/users/whoami', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.getToken(),
            },
        })

        if (!response.ok) {
            throw new HttpError(response.statusText, response.status)
        }

        const data: User = await response.json()
        return data
    }

    public async getUserAndOrgData(
        ownerId: string
    ): Promise<UsersLoginResponse> {
        const token = this.getToken()
        if (!token) {
            throw new Error('No token found')
        }
        const response = await fetch(apiUrl + '/users/login', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'X-Owner-Id': ownerId,
            },
        })
        if (response.status !== 200) {
            if (response.status === 401) {
                // console.log(
                //     'Unauthorized, throwing unauthorized error: ',
                //     response.status
                // )
                throw new HttpError('Unauthorized', 401)
            }
            throw new Error(response.statusText)
        }
        const data: UsersLoginResponse = await response.json()
        return data
    }

    public async getUserFromToken(): Promise<User | null> {
        // console.log('Requesting user from token...')
        // console.log('Token: ', this.getToken())
        // console.log('API URL: ', apiUrl)
        const response = await fetch(apiUrl + '/users/login', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + this.getToken(),
            },
        })
        if (response.status == 404) {
            // console.log('User not found')
            return null
        } else if (response.status !== 200) {
            // console.log('Error: ', response.statusText)
            throw new Error(response.statusText)
        }

        const data: User = await response.json()
        return data
    }

    public async createUserFromToken(
        accountType: string,
        orgCreateData?: object
    ): Promise<User | null> {
        // console.log('Requesting user creation with account type: ', accountType)
        let response: Response
        if (accountType === 'individual') {
            response = await fetch(apiUrl + '/users/login', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + this.getToken(),
                    'X-Account-Type': accountType,
                },
            })
        } else if (accountType === 'organization') {
            response = await fetch(apiUrl + '/users/login', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + this.getToken(),
                    'X-Account-Type': accountType,
                },
                body: JSON.stringify(orgCreateData),
            })
        } else {
            // console.log('Invalid account type')
            return null
        }

        if (response.status == 404) {
            return null
        }
        if (!response.ok) {
            throw new Error(response.statusText)
        }

        const data: User = await response.json()
        // console.log('data: ', data)

        return data
    }

    public async getUser(userId: number): Promise<User> {
        const response = await fetch(apiUrl + '/users/' + userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.getToken(),
            },
        })

        if (!response.ok) {
            throw new HttpError(response.statusText, response.status)
        }

        const data: User = await response.json()
        return data
    }

    public async patchUser(userId: number, data: object): Promise<User> {
        const response = await fetch(apiUrl + '/users/' + userId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.getToken(),
            },
            body: JSON.stringify(data),
        })

        if (!response.ok) {
            throw new HttpError(response.statusText, response.status)
        }

        const responseData: User = await response.json()
        return responseData as User
    }

    public async getAPIToken(): Promise<string> {
        const response = await fetch(apiUrl + '/users/api_token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.getToken(),
            },
        })

        if (!response.ok) {
            throw new HttpError(response.statusText, response.status)
        }

        const data = await response.json()
        return data.token
    }
}

export default UserService
