import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ClusterService } from '../libs/ambient_api/ClusterService'
import { CreateClusterRequest } from '../types/ApiRequests'
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Modal,
    TextField,
    Typography,
    SelectChangeEvent,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NodeService } from '../libs/ambient_api/NodeService'
import { Node } from '../types/TechnicalTypes'

interface AddClusterPanelProps {
    onClose: () => void
    token: string
}

interface FormData {
    name: string
    description: string
    tags: string
    node_ids: number[] // Assuming node_ids are numbers, change to string[] if they are strings
}

const AddClusterPanel: FC<AddClusterPanelProps> = ({ onClose, token }) => {
    const theme = useTheme()

    const [formData, setFormData] = useState<FormData>({
        name: '',
        description: '',
        tags: '',
        node_ids: [], // Initialize as an empty array of numbers (or strings)
    })
    const [showWaitingScreen, setShowWaitingScreen] = useState(false)
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)
    const [nodeList, setNodeList] = useState<Node[]>([])
    const [selectedNodes, setSelectedNodes] = useState<Node[]>([])

    useEffect(() => {
        const fetchNodes = async () => {
            const nodeService = new NodeService()
            const nodes = await nodeService.getNodes(token)
            const nodesWithoutCluster = nodes.filter((node) => !node.cluster_id)
            setNodeList(nodesWithoutCluster)
        }
        fetchNodes()
    }, [token])

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value as string,
            })
        }
    }
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }

    const validate = (): boolean => {
        if (!formData.name || !formData.description) {
            alert('All fields are required')
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        // validate form
        // console.log('Validating form...')
        if (!validate()) {
            return
        }

        // show waiting screen
        setShowWaitingScreen(true)

        // create cluster
        const requestBody: CreateClusterRequest = {
            cluster_data: {
                name: formData.name,
                description: formData.description,
                tags: formData.tags.split(',').map((tag) => tag.trim()),
            },
            node_ids: formData.node_ids,
        }
        const clusterService = new ClusterService(token)
        const response = await clusterService.createCluster(requestBody)
        // console.log('Response: ' + response)

        // hide waiting screen
        setShowWaitingScreen(false)

        // show success screen
        setShowSuccessScreen(true)
    }

    const waitingScreen = (
        <div className="fixed inset-0 bg-black bg-opacity-70">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-stone-100 p-5 rounded-lg font-sans">
                <div className="text-center">
                    <h2 className="text-2xl">Creating cluster...</h2>
                </div>
            </div>
        </div>
    )

    const successScreen = (
        <div className="fixed inset-0 bg-black bg-opacity-70">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-stone-100 p-5 rounded-lg font-sans w-[250px]">
                <div className="text-center">
                    <h2 className="text-2xl">🎉 Cluster created! 🎉</h2>
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )

    return ReactDOM.createPortal(
        <Modal
            open={true}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Create Cluster
                </Typography>
                <form>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Tags"
                            name="tags"
                            value={formData.tags}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {/* <FormControl fullWidth margin="normal">
                        <InputLabel>Nodes</InputLabel>
                        <Select
                            name="node_ids"
                            onChange={handleSelectChange}
                            multiple
                            value={formData.node_ids as unknown as string}  // Use the array directly
                        >
                            {nodeList.map((node) => (
                                <MenuItem key={node.id} value={node.id}>
                                    <Checkbox
                                        checked={formData.node_ids.indexOf(node.id) > -1}  // Compare with node ID
                                    />
                                    <ListItemText primary={node.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Nodes</InputLabel>
                        <Select
                            name="node_ids"
                            onChange={handleSelectChange}
                            multiple
                            value={formData.node_ids as unknown as string} // Use the array directly
                        >
                            {nodeList.map((node) => (
                                <MenuItem key={node.id} value={node.id}>
                                    {node.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                </form>
                {showWaitingScreen && waitingScreen}
                {showSuccessScreen && successScreen}
            </div>
        </Modal>,
        document.getElementById('add-cluster-portal-root')!
    )
}

export default AddClusterPanel
