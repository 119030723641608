import { Cluster, ClusterCreate } from '../../types/TechnicalTypes'
import { HttpError } from '../../types/Errors'
import { GetClustersResponse } from '../../types/ApiResponses'
import { CreateClusterRequest } from '../../types/ApiRequests'
import BaseService from './BaseService'

export class ClusterService extends BaseService {
    constructor(token: string) {
        super(token)
    }
    public async createCluster(
        cluster: CreateClusterRequest
    ): Promise<Cluster> {
        // console.log('Creating cluster...')
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/clusters`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cluster),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new Error(response.statusText)
        }

        const parsedResponse: Cluster = await response.json()
        // console.log('Cluster created:', parsedResponse)
        return parsedResponse as Cluster
    }

    public async getCluster(clusterId: string): Promise<Cluster> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/clusters/${clusterId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            if (response.status === 404) {
                // console.log('Cluster not found')
                // return blank cluster
                return null as unknown as Cluster
            }
            throw new Error(response.statusText)
        }
        const parsedResponse: Cluster = await response.json()
        return parsedResponse as Cluster
    }

    public async getClusters(): Promise<Cluster[]> {
        const ownerId = localStorage.getItem('ownerId')
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/clusters`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                return []
            }
            return []
        }
        const parsedResponse: GetClustersResponse = await response.json()
        const clusters = (await parsedResponse.results) as Cluster[]
        return clusters as Cluster[]
    }

    public async putCluster(
        clusterId: number,
        cluster: Cluster
    ): Promise<Cluster> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/clusters/${clusterId}`,
            {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(cluster),
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new Error(response.statusText)
        }
        const parsedResponse: Cluster = await response.json()
        return parsedResponse as Cluster
    }

    public async deleteCluster(clusterId: number): Promise<void> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/clusters/${clusterId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new Error(response.statusText)
        }
    }
}
