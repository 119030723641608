import React, {
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    FC,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Grid, Typography, Paper, Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Terminal from './Terminal'
import { Terminal as XTerm } from 'xterm'
import {
    NodeService,
    RunCommandOutput,
    RunCommandResponse,
} from '../libs/ambient_api/NodeService'
import LinearProgress from '@mui/material/LinearProgress'
import { CopyBlock } from 'react-code-blocks'
import { useTheme } from '../ThemeContext'

interface LaunchTerminalPanelProps {
    open: boolean
    onClose: (confirmed: boolean) => void
    token: string
    nodeIds: number[]
    username: string | undefined
    hostname: string | undefined
}

const LaunchTerminalPanel: FC<LaunchTerminalPanelProps> = ({
    open,
    onClose,
    token,
    nodeIds,
    username,
    hostname,
}) => {
    const theme = useTheme()
    const [confirmed, setConfirmed] = useState<boolean>(false)
    const terminalRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const nodeService = new NodeService()

    const handleCancel = () => {
        setConfirmed(false)
        onClose(false)
    }

    const [showTerminal, setShowTerminal] = useState<boolean>(true)
    const [showOutputs, setShowOutputs] = useState<boolean>(false)
    const [output, setOutput] = useState<RunCommandOutput | null>(null) // Ensure outputs is always an array
    const [commandObj, setCommandObj] = useState<RunCommandResponse | null>(
        null
    )
    const [refresh, setRefresh] = useState<boolean>(false)

    const handleEnter = async (command: string, xterm: XTerm | null) => {
        setShowTerminal(false)
        const command_list = command.split(' ')

        if (!xterm) return

        xterm.write(`\r\n`)
        xterm.write(`Running parsed command [ ${command_list.join(`, `)} ] ...`)

        console.log('Command entered:', command)

        try {
            const newCommand = await nodeService.runCommand(
                token,
                nodeIds,
                command_list
            )
            console.log('New command:', newCommand)
            setCommandObj(newCommand)
        } catch (error) {
            console.error('Failed to run command:', error)
            // Handle error appropriately
        }
    }

    useEffect(() => {
        const fetchCommandOutput = async () => {
            if (commandObj) {
                console.log('Fetching output for command:', commandObj)
                await nodeService.getCommandOutputWebsocket(
                    commandObj.id,
                    (event: MessageEvent) => {
                        const data: RunCommandOutput = JSON.parse(event.data)
                        console.log('in setOutputs', data)
                        // Ensure the data is an array
                        setOutput(data)
                        console.log('output', output)
                    },
                    token
                )
                setShowOutputs(true)
            }
        }

        fetchCommandOutput()
    }, [commandObj, token])

    const displayOutputs = (
        <Box>
            {/* if status is pending, show linear progress bas */}
            {output?.status === 'pending' && <LinearProgress />}
            {output?.status === 'success' && (
                <Box>
                    <Typography>Command executed successfully!</Typography>
                    <Typography>Output:</Typography>
                    <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
                        <CopyBlock
                            text={output?.output || ''}
                            language={'shell'}
                            showLineNumbers={false}
                            codeBlock
                        />
                    </Box>
                </Box>
            )}
            {output?.status === 'failed' && (
                <Box>
                    <Typography>Command execution failed</Typography>
                    <Typography>{output?.output}</Typography>
                </Box>
            )}
        </Box>
    )

    const singleCommadTerminal = (
        <Box maxHeight={200}>
            {showTerminal && (
                <Terminal
                    ref={terminalRef}
                    username={username || 'ambient'}
                    hostname={hostname || 'localhost'}
                    handleSubmit={handleEnter}
                />
            )}
            {showOutputs && displayOutputs}
        </Box>
    )

    return (
        <Modal open={open} onClose={handleCancel}>
            <Paper
                style={{
                    width: '50%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Grid container margin={3} direction="column">
                    <Grid item justifyContent={'center'}>
                        <Typography
                            variant="h4"
                            padding={2}
                            paddingLeft={3}
                            paddingBottom={4}
                        >
                            Launch Terminal
                        </Typography>
                    </Grid>

                    <Grid>
                        <Accordion style={{ maxWidth: '90%' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography
                                    style={{ flexShrink: 0, width: '33%' }}
                                >
                                    Run Command
                                </Typography>
                                <Typography color={'text.secondary'}>
                                    Run a Command Asynchronously
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {singleCommadTerminal}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{ maxWidth: '90%' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                            >
                                <Typography
                                    style={{ flexShrink: 0, width: '33%' }}
                                >
                                    Launch Terminal
                                </Typography>
                                <Typography color={'text.secondary'}>
                                    Launch a Terminal
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    This feature is under development and will
                                    be available soon.
                                </Typography>
                            </AccordionDetails>
                            <AccordionActions>
                                <Button size="small" disabled>
                                    Launch
                                </Button>
                            </AccordionActions>
                        </Accordion>
                    </Grid>

                    <Grid item justifyItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancel}
                            style={{
                                marginTop: 24,
                                left: '40%',
                                marginBottom: 24,
                            }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default LaunchTerminalPanel
