import { useNavigate } from 'react-router'

export const HomeButton: React.FC = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/home')
    }

    return (
        <button
            className=" w-[50px] h-[52px] absolute top-[6px] left-[28px] rounded-full"
            onClick={handleClick}
        >
            <img
                className="w-[50px] h-[52px] absolute left-[2px] bottom-[1px]"
                src="/vector.svg"
                alt="Home"
            />
        </button>
    )
}
