import { Service } from '../types/TechnicalTypes'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import {
    Button,
    Modal,
    Typography,
    Grid,
    Paper,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
} from '@mui/material'
import { useState } from 'react'
import { NodeService, NodeOrder } from '../libs/ambient_api/NodeService'
import { useEffect } from 'react'
import { HttpError } from '../types/Errors'

interface AddNodeToAppPanelProps {
    onClose: () => void
    application: Service
    token: string
}

const AddNodeToAppPanel: React.FC<AddNodeToAppPanelProps> = ({
    onClose,
    application,
    token,
}) => {
    const [nodeId, setNodeId] = useState<string>('')
    const [nodes, setNodes] = useState<any[]>([])
    const [node, setNode] = useState<any | null>(null)
    const [nodeService, setNodeService] = useState<NodeService | null>(null)

    const handleSave = async () => {
        if (!nodeId) {
            return
        }
        if (!nodeService) {
            return
        }
        const node = await nodeService.getNode(token, nodeId)
        if (!node) {
            return
        }
        const applicationService = new ApplicationService(token)
        console.log(
            'AddNodeToAppPanel.handleSave: ' + application.id + ' ' + node.id
        )
        try {
            await applicationService.addNodeToApp(application.id, node.id)
        } catch (error: any) {
            console.error('AddNodeToAppPanel.handleSave: ' + error)
            if (error instanceof HttpError) {
                if (error.statusCode === 400) {
                    // show error message
                    alert('Node already added to application')
                }
            }
        }
        onClose()
    }

    useEffect(() => {
        fetchNodes()
    }, [])

    const fetchNodes = async () => {
        const nodeService = new NodeService()
        const nodes = await nodeService.getNodes(
            token,
            'timestamp',
            NodeOrder.desc,
            null
        )
        setNodes(nodes)
        setNodeService(nodeService)
    }

    const handleNodeChange = (event: SelectChangeEvent) => {
        console.log('AddNodeToAppPanel.handleNodeChange: ' + event.target.value)
        setNodeId(event.target.value)
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="add-node-to-app-panel"
            aria-describedby="add-node-to-app-panel"
        >
            <Grid
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 24,
                }}
            >
                <Paper
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: 24,
                        height: 300,
                        width: 400,
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        style={{
                            marginBottom: 24,
                            padding: '40pz',
                            paddingBottom: '30px',
                        }}
                    >
                        <Typography variant="h4">Add Node to App</Typography>
                    </Grid>
                    <FormControl fullWidth>
                        <InputLabel id="node-select-label">Node</InputLabel>
                        <Select
                            labelId="node-select-label"
                            id="node-select"
                            value={nodeId}
                            label="Node"
                            onChange={handleNodeChange}
                        >
                            {nodes.map((node) => (
                                <MenuItem key={node.id} value={node.id}>
                                    {node.name} [ID: {node.id}] - [{node.role}]
                                    - {node.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid container direction="row" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            style={{ marginTop: 24 }}
                        >
                            Add Node
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    )
}

export default AddNodeToAppPanel
