import { Button, Grid, Typography, Paper, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import UserService from '../libs/ambient_api/UserService'
import Switch from '@mui/material/Switch'
import { UserPreferences } from '../types/User'
import { useTheme } from '../ThemeContext'
import { User } from '../types/User'
import { HomeButton } from '../components/HomeButton'

const PreferencesPage: React.FC = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState<string>('')
    const [user, setUser] = useState<User | null>(null)
    const { themeMode, toggleTheme } = useTheme()

    const toggleThemeAndPatchPreference = () => {
        console.log('Toggling theme')
        toggleTheme()
        if (!token || !user) {
            console.log('No token, or user')
            console.log('Token: ', token, 'User: ', user)
            return
        }
        const userService = new UserService(token)
        const patchUser = async () => {
            console.log('themeMode:', themeMode)
            const patch_data = {
                preferences: {
                    dark_mode: themeMode === 'light',
                },
            }
            console.log(
                'toggleThemeAndPatchPreference() - patch_data:',
                patch_data
            )
            const patchedUser = await userService.patchUser(
                user!.id,
                patch_data
            )
            console.log('Patched user:', patchedUser)
        }
        patchUser()
    }

    // auth useEffect
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (token) {
            setToken(token)
        } else {
            navigate('/home')
        }
    }, [navigate])

    // get user preferences
    useEffect(() => {
        if (!token) {
            return
        }
        const userService = new UserService(token)
        const fetchUser = async () => {
            const fetchedUser = await userService.getUserFromToken()
            if (!fetchedUser) {
                return
            }
            console.log('Fetched user:', fetchedUser)
            setUser(fetchedUser)
        }
        fetchUser()
    }, [token])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            style={{ font: 'sans' }}
        >
            <Grid item xs={8}>
                <HomeButton />
            </Grid>
            <Grid item xs={8}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h4">Preferences</Typography>
                    <Typography variant="h6">Theme</Typography>
                    <Typography variant="body1">Dark Mode</Typography>
                    <Switch
                        checked={themeMode === 'dark'}
                        onChange={toggleThemeAndPatchPreference}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default PreferencesPage
