import React, { useState, FormEvent, useRef, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Paper,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    FormControl,
    TextField,
    Select,
    SelectChangeEvent,
    MenuItem,
    Backdrop,
    CircularProgress,
    Chip,
    Modal,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react'
import PersonIcon from '@mui/icons-material/Person'
import GroupIcon from '@mui/icons-material/Group'

interface FormData {
    name: string
    description: string
    subscription: string
    rootemail: string
}

interface FormErrors {
    default: string
}

const cardHeight = 200

const SignupPage: React.FC = () => {
    const theme = useTheme()
    const { loginWithRedirect, getAccessTokenSilently, isAuthenticated } =
        useAuth0()
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        subscription: '',
        rootemail: '',
    })
    // const [errors, setErrors] = useState<FormErrors>({});
    // const captchaRef = useRef<any | null>(null);
    const navigate = useNavigate()

    // workflow states
    const [showChooseAcctTypeScreen, setShowChooseAcctTypeScreen] =
        useState<boolean>(true)
    const [showOrgInfoScreen, setShowOrgInfoScreen] = useState<boolean>(false)
    const [showWaitingScreen, setShowWaitingScreen] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }

    // const validate = (): FormErrors => {
    //     const newErrors: FormErrors = {}
    //     if (!formData.name || !formData.rootemail || !formData.subscription) {
    //         newErrors.default = 'All fields are required'
    //     }
    //     return newErrors
    // }

    // useEffect(() => {
    //     if (!isAuthenticated) {
    //         loginWithRedirect();
    //     }
    // }, [isAuthenticated, loginWithRedirect]);

    const handleSubmit = async () => {
        try {
            await loginWithRedirect()
            const token = await getAccessTokenSilently()
            // Now use this token to make a backend API call
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Account-Type': 'organization',
            }
            const body = JSON.stringify(formData)
            // console.log('body: ', body)
            const url = `${process.env.REACT_APP_API_URL}/users`
            console.log('API url: ', url)
            const response = await fetch(url, {
                method: 'POST',
                headers,
                body,
            })
            if (!response.ok) throw new Error(response.statusText)
            navigate('/')
        } catch (e) {
            // console.log(e)
        }
    }

    const onPersonalButtonClick = async () => {
        // console.log('onPersonalButtonClick')
        // redirect to Auth0 signup page with app_metadata
        setShowChooseAcctTypeScreen(false)
        setShowWaitingScreen(true)
        // await loginWithRedirect()
        // const token = await getAccessTokenSilently()
        const token = localStorage.getItem('access_token')
        // console.log('token: ', token)
        // Now use this token to make a backend API call
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Account-Type': 'individual',
        }
        // console.log('headers: ', headers)
        const url = `${process.env.REACT_APP_API_URL}/users`
        console.log('API url: ', url)
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
            })
            if (!response.ok) {
                // console.log('response error: ', response.text)
                throw new Error(response.statusText)
            }
            // console.log('user creation response: ', response.json())
        } catch (e) {
            // console.log(e)
        }
        navigate('/')
    }

    const onBusinessButtonClick = () => {
        setShowChooseAcctTypeScreen(false)
        setShowOrgInfoScreen(true)
    }

    const waitingScreen = (
        <Backdrop
            open={true}
            style={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
            <Typography style={{ marginLeft: '15px' }}>
                Adding node...
            </Typography>
        </Backdrop>
    )

    const choostAcctTypeScreen = (
        <Grid
            item
            xs={12}
            style={{
                marginTop: '100px',
            }}
        >
            <Grid container justifyContent={'center'}>
                <Paper
                    color={theme.palette.background.paper}
                    style={{
                        padding: theme.spacing(2),
                        textAlign: 'center',
                        color: theme.palette.text.secondary,
                        // maxWidth: '400px',
                    }}
                >
                    {/* This hiraerchy may need another grid container to organize all these items */}
                    <Typography
                        variant="h5"
                        component="h2"
                        style={{
                            margin: '20px',
                        }}
                    >
                        Choose Account Type
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        Select the type of account you would like to create.
                    </Typography>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid
                            item
                            xs={5}
                            style={{
                                margin: '10px',
                            }}
                        >
                            <Card
                                style={{
                                    height: cardHeight,
                                }}
                            >
                                <CardContent>
                                    <Grid
                                        container
                                        direction={'column'}
                                        alignContent={'space-around'}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="body"
                                            fontFamily={'sans-serif'}
                                            style={{
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Personal
                                        </Typography>
                                        <Typography variant="body2">
                                            Best suited for individuals
                                        </Typography>
                                        <Grid
                                            item
                                            style={{
                                                marginTop: '70px',
                                            }}
                                        >
                                            <Chip
                                                label="Create Personal Account"
                                                onClick={onPersonalButtonClick}
                                                color="primary"
                                                icon={<PersonIcon />}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            style={{
                                margin: '10px',
                            }}
                        >
                            <Card
                                style={{
                                    height: cardHeight,
                                }}
                            >
                                <CardContent>
                                    <Grid
                                        container
                                        direction={'column'}
                                        alignContent={'space-around'}
                                    >
                                        <Typography variant="h5" component="h2">
                                            Business
                                        </Typography>
                                        <Typography variant="body2">
                                            Best suited for businesses or
                                            organizations with multiple users
                                        </Typography>
                                        <Grid
                                            item
                                            style={{
                                                marginTop: '60px',
                                            }}
                                        >
                                            <Chip
                                                label="Create Business Account"
                                                onClick={onBusinessButtonClick}
                                                color="secondary"
                                                icon={<GroupIcon />}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <ReCAPTCHA
                    className="absolute top-[566px] left-[64px] w-[340px] h-[63px]"
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}
                    ref={captchaRef}
                /> */}
                </Paper>
            </Grid>
        </Grid>
    )

    const orgInfoScreen = (
        <Grid item xs={12}>
            <Grid
                container
                justifyContent={'center'}
                style={{
                    marginTop: '100px',
                }}
            >
                <Paper
                    color={theme.palette.background.paper}
                    style={{
                        padding: theme.spacing(2),
                        textAlign: 'center',
                        color: theme.palette.text.secondary,
                    }}
                >
                    <Grid
                        container
                        direction={'column'}
                        justifyContent={'center'}
                        style={{
                            maxWidth: '400px',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h2"
                            style={{
                                marginBottom: '20px',
                            }}
                        >
                            Organization Information
                        </Typography>
                        <form>
                            <FormControl>
                                <TextField
                                    label="Organization Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    style={{
                                        marginBottom: '10px',
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    style={{
                                        marginBottom: '10px',
                                    }}
                                />
                            </FormControl>
                            {/* <FormControl>
                                <Select
                                    label="Subscription"
                                    name="subscription"
                                    value={formData.subscription}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="free">Free</MenuItem>
                                    <MenuItem value="basic">Basic</MenuItem>
                                    <MenuItem value="pro">Premium</MenuItem>
                                </Select>
                            </FormControl> */}
                            <FormControl>
                                <TextField
                                    label="Root Email"
                                    name="rootemail"
                                    value={formData.rootemail}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </form>
                        <Chip
                            label="Create Account"
                            onClick={handleSubmit}
                            color="secondary"
                            style={{
                                marginTop: '40px',
                            }}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )

    return (
        <Grid container spacing={2}>
            {showChooseAcctTypeScreen && choostAcctTypeScreen}
            {showWaitingScreen && waitingScreen}
            {showOrgInfoScreen && orgInfoScreen}
        </Grid>
    )
}

export default SignupPage
